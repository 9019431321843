import React, { useContext } from "react";
import { AuthProvider } from "./utils/AuthContext";
import Main from "./navigation/Main";


function App() {

  return (
    <AuthProvider>
     <Main />
    </AuthProvider>
  );
}

export default App;
