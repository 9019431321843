import React, { useState } from "react";

import MainNav from "../components/MainNav";
import Sidebar from "../components/Sidebar";
import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { useRef } from "react";
import "./print.css";

function EnrollmentsReport() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [enrollments, setEnrollments] = useState([]);
  const tableRef = useRef(null);
  const [totalEarnings, setTotalEarnings] = useState("");
  const [paymentType, setPaymentType] = useState("Zaad");
  const print = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <link rel="stylesheet" type="text/css" href="print.css" media="print">
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
        </head>
        <body>
          ${tableRef.current.outerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const generateReport = (e) => {
    e.preventDefault();
    const collectionRef = collection(db, "enrollment");
    const startTimestamp = Timestamp.fromDate(new Date(`${fromDate}T00:00:00`));
    const endTimestamp = Timestamp.fromDate(new Date(`${toDate}T23:59:59`));
    let apiType;
    let q;

    if (
      paymentType === "Zaad" ||
      paymentType === "eDahab" ||
      paymentType === "Manual"
    ) {
      // If paymentType is Zaad, eDahab, or Manual, set the apiType and add the where query
      apiType = paymentType;

      q = query(
        collectionRef,
        where("enrolmentDate", ">=", startTimestamp),
        where("enrolmentDate", "<=", endTimestamp),
        where("isCourseFree", "==", "No"),
        where("apiType", "==", apiType)
      );
    } else if (paymentType === "All") {
      // If paymentType is All, don't include the apiType in the where query
      apiType = "";

      q = query(
        collectionRef,
        where("enrolmentDate", ">=", startTimestamp),
        where("enrolmentDate", "<=", endTimestamp),
        where("isCourseFree", "==", "No")
      );
    }

    let total = 0;
    getDocs(q)
      .then((querySnapshot) => {
        const enrollmentData = [];
        querySnapshot.forEach((doc) => {
          enrollmentData.push({ id: doc.id, ...doc.data() });
          let convertedString = {
            amount: Number(doc.data().amount),
          };
          total += convertedString.amount;
        });
        setEnrollments(enrollmentData);
        setTotalEarnings(total);
      })
      .catch((error) => {
        console.error("Error querying enrollments:", error);
      });
  };
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <>
            <div className="courses mx-6">
              <div className="courseHeader flex justify-center flex-col my-6">
                <h1 className="text-3xl py-2 text-primaryColor text-center font-bold tracking-widest">
                  Course Enrollments Report
                </h1>
                <div className="flex flex-col items-center p-8 space-y-4 bg-gray-100">
                  <h2 className="text-2xl font-semibold">Generate Report</h2>
                  <div className="w-full max-w-md p-4 bg-white rounded shadow-md">
                    <form className="flex flex-col space-y-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          From Date
                        </label>
                        <input
                          type="date"
                          className="mt-1 p-2 w-full rounded-md shadow-sm focus:ring focus:ring-primaryColor focus:outline-none"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          To Date
                        </label>
                        <input
                          type="date"
                          className="mt-1 p-2 w-full rounded-md shadow-sm focus:ring focus:ring-primaryColor focus:outline-none"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Payment Type
                        </label>
                        <select
                          className="mt-1 p-2 w-full rounded-md shadow-sm focus:ring focus:ring-primaryColor focus:outline-none"
                          value={paymentType}
                          onChange={(e) => setPaymentType(e.target.value)}
                          required
                        >
                          <option value="Zaad">Zaad</option>
                          <option value="eDahab">eDahab</option>
                          <option value="Manual">Manual</option>
                          <option value="All">All</option>
                        </select>
                      </div>
                      <button
                        type="submit"
                        className="px-4 py-2 text-white bg-primaryColor rounded-md hover:bg-opacity-80 focus:outline-none"
                        onClick={generateReport}
                      >
                        Generate
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center mb-4">
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-opacity-80 focus:outline-none"
                  onClick={print}
                >
                  <i className="fas fa-print"></i> Print Report
                </button>
              </div>
              <div class="overflow-scroll relative" ref={tableRef}>
                {totalEarnings && (
                  <h1 className="text-primaryColor text-2xl font-semibold text-center">
                    Total Earnings: ${totalEarnings}
                  </h1>
                )}

                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Course name
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Payment Type
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Student Phone
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {enrollments.map((enrollment) => (
                      <tr key={enrollment.id}>
                        <td className="py-4 px-6">{enrollment.courseName}</td>
                        <td className="py-4 px-6">{enrollment.apiType}</td>
                        <td className="py-4 px-6">${enrollment.amount}</td>
                        <td className="py-4 px-6">{enrollment.enrolmentDate.toDate().toLocaleDateString('en-US')}</td>
                        <td className="py-4 px-6">
                          {enrollment.apiType === "Manual"
                            ? "Manual"
                            : enrollment.paymentPhone}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {totalEarnings && (
                  <h1 className="text-primaryColor text-2xl font-semibold text-center">
                    Total Earnings: ${totalEarnings}
                  </h1>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentsReport;
