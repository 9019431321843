import React, { useContext } from "react";

import MainNav from "../components/MainNav";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import Sidebar from "../components/Sidebar";
import { AuthContext } from "../utils/AuthContext";
import readingClub from "../assets/readingClub.jpg";
import { useRef } from "react";
import "./print.css";
const chartSetting = {
  xAxis: [
    {
      label: "Month",
    },
  ],
  width: 500,
  height: 400,
};
function YearlyReport() {
  const tableRef = useRef(null);
  const {
    noOfStudentsByYear,
    mobileappUsers,
    webappUsers,
    totalCourses,
    monthlyEnrollments,
    totalSubscriptions,
    totalSubscriptionsEarnings,
    totalEnrollments,
    totalEarnings,
  } = useContext(AuthContext);
  const currentYear = new Date().getFullYear();
  const print = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Report</title>
         
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
          <script src="https://cdn.tailwindcss.com"></script>
          <script>
            tailwind.config = {
                theme: {
                    extend: {
                      colors: {
                      [: "#007474",
                      whiteColor:"#e1fafa",
                      greyColor:"#BDB8B8",
                      [#ffb606]:"#ffb606",
                      thirdColor:"#014343",
                    },
                    },
            }
          </script>
        </head>
        <body >
          ${tableRef.current.outerHTML}
        </body>

      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <>
            <div className="courses mx-6">
              <div className="courseHeader flex justify-center flex-col my-6">
                <h1 className="text-3xl py-2 text-[#007474] text-center font-bold tracking-widest">
                  Yearly Course Report
                </h1>
                <div className="flex flex-col items-center p-8 space-y-4 bg-gray-100">
                  <h2 className="text-2xl font-semibold">Generate Report</h2>
                  {/* <div className="w-full max-w-md p-4 bg-white rounded shadow-md">
                     <form className="flex flex-col space-y-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          From Date
                        </label>
                        <input
                          type="date"
                          className="mt-1 p-2 w-full rounded-md shadow-sm focus:ring focus:ring-[#007474] focus:outline-none"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          To Date
                        </label>
                        <input
                          type="date"
                          className="mt-1 p-2 w-full rounded-md shadow-sm focus:ring focus:ring-[#007474] focus:outline-none"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Payment Type
                        </label>
                        <select
                          className="mt-1 p-2 w-full rounded-md shadow-sm focus:ring focus:ring-[#007474] focus:outline-none"
                          value={paymentType}
                          onChange={(e) => setPaymentType(e.target.value)}
                          required
                        >
                          <option value="Zaad">Zaad</option>
                          <option value="eDahab">eDahab</option>
                          <option value="Manual">Manual</option>
                          <option value="All">All</option>
                        </select>
                      </div>
                      <button
                        type="submit"
                        className="px-4 py-2 text-white bg-[#007474] rounded-md hover:bg-opacity-80 focus:outline-none"
                        onClick={generateReport}
                      >
                        Generate
                      </button>
                    </form> 
                  </div> */}
                </div>
              </div>
              <div className="flex items-center justify-center mb-4">
                <button
                  type="submit"
                  className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-opacity-80 focus:outline-none"
                  onClick={print}
                >
                  <i className="fas fa-print"></i> Print Report
                </button>
              </div>
              <div
                class="overflow-y-auto overflow-x-hidden relative"
                ref={tableRef}
              >
                {/* Report Starts Here */}
                <div>
                  <div className="flex items-center justify-between bg-[#007474] min-h-20 pr-6 py-4">
                    <img
                      src="https://www.razeenonline.com/static/media/logo.655e787b28035e46bb46.png"
                      className="h-10 object-contain"
                      alt="Logo"
                    />
                    <div className="text-white">
                      <h1 className="text-4xl font-extrabold">{currentYear}</h1>
                      <h1 className="">Yearly Report </h1>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div>
                    <h1 className="text-4xl font-extrabold text-[#007474]">
                      Razeen Training Center
                    </h1>
                    <br />
                    <div className="flex  w-full gap-x-3 ">
                      <div className="border shadow-lg min-h-[18rem] w-1/2 rounded-lg ">
                        <h1 className="text-black bg-[#ffb606] border-b  h-14 text-center p-4 font-bold text-lg">
                          Students Report
                        </h1>
                        <br />
                        <h1 className="pl-2 text-">
                          Total Students Registered:{" "}
                          <span className="text-[#007474] font-bold">
                            {noOfStudentsByYear}
                          </span>
                        </h1>
                        <h1 className="pl-2 text-lg font-semibold">
                          App Users Report:{" "}
                        </h1>
                        <h1 className="pl-2 text-">
                          Mobile App Users:{" "}
                          <span className="text-[#007474] font-bold">
                            {mobileappUsers}
                          </span>
                        </h1>
                        <h1 className="pl-2 text-">
                          Web App Users:{" "}
                          <span className="text-[#007474] font-bold">
                            {webappUsers}
                          </span>
                        </h1>
                        <br />
                        <div className="flex flex-start ">
                          <PieChart
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: `${mobileappUsers}`,
                                    label: "Mobile App Users",
                                  },
                                  {
                                    id: 1,
                                    value: `${webappUsers}`,
                                    label: "Web App Users",
                                  },
                                ],
                                highlightScope: {
                                  faded: "global",
                                  highlighted: "item",
                                },
                                faded: {
                                  innerRadius: 30,
                                  additionalRadius: -30,
                                  color: "gray",
                                },
                                cx: 120,
                                cy: 100,
                              },
                            ]}
                            width={400}
                            height={200}
                          />
                          <br />
                          <br />
                        </div>
                      </div>
                      <div className="border shadow-lg min-h-[18rem] w-1/2 rounded-lg ">
                        <h1 className="text-black bg-teal-400 border-b  h-14 text-center p-4 font-bold text-lg">
                          Courses & Enrollments Report
                        </h1>
                        <br />
                        <h1 className="pl-3">
                          Total Courses:{" "}
                          <span className="text-[#007474] font-bold  text-xl">
                            {totalCourses}
                          </span>
                        </h1>
                        <h1 className="pl-3">
                          Total Sections & Lessons:{" "}
                          <span className="text-[#007474] font-bold  text-xl">500+</span>
                        </h1>
                        <h1 className="pl-3">
                          Most Paid Enrolled Course:{" "}
                          <span className="text-[#007474] font-bold  text-xl">
                            Keys to financial wealth
                          </span>
                        </h1>
                        <h1 className="pl-3">
                          Most Free Enrolled Course:{" "}
                          <span className="text-[#007474] font-bold  text-xl">
                            Isbedelka Wanaagsan
                          </span>
                        </h1>
                        <h1 className="pl-3">
                          Total Course  Enrollments:{" "}
                          <span className="text-[#007474] font-bold  text-xl">
                            {totalEnrollments} Enrollments
                          </span>
                        </h1>
                        {/* <h1 className="pl-3">
                          Total Courses Eearnings:{" "}
                          <span className="text-[#007474] font-bold  text-xl">
                            ${totalEarnings} 
                          </span>
                        </h1> */}
                        <br />
                        <div className="flex flex-start mb-20 ">
                          <PieChart
                            colors={["#bf2ffa", "orange"]}
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: `${totalCourses}`,
                                    label: `${totalCourses} Courses`,
                                  },
                                  {
                                    id: 1,
                                    value: `500`,
                                    label: `500+ Lessons & Sections`,
                                  },
                                ],
                                highlightScope: {
                                  faded: "global",
                                  highlighted: "item",
                                },
                                faded: {
                                  innerRadius: 30,
                                  additionalRadius: -30,
                                  color: "gray",
                                },
                              },
                            ]}
                            width={600}
                            height={200}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="flex w-full gap-x-3">
                      <div className="border shadow-lg min-h-[18rem] w-full rounded-lg ">
                        <h1 className="text-white bg-red-400 border-b  h-14 text-center p-4 font-bold text-lg">
                          Reading Club Report
                        </h1>
                        <br />
                        <div className="flex items-center w-full justify-between">
                         <div className="w-1/2">
                         <h1 className="pl-3">
                            Total Subscriptions:{" "}
                            <span className="text-[#007474] font-bold text-xl">
                              {totalSubscriptions} Subscribers
                            </span>
                          </h1>
                          {/* <h1 className="pl-3">
                            Total Earnings:{" "}
                            <span className="text-[#007474] font-bold text-xl">
                              ${totalSubscriptionsEarnings}
                            </span>
                          </h1> */}
                         </div>

                          <img
                            src={readingClub}
                            alt="Reading Club"
                            className="max-h-80  object-cover object-top w-1/2 "
                          />
                        </div>
                        <br />
                      </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default YearlyReport;
