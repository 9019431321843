import React, { useEffect, useState } from "react";
import AddCourse from "../components/AddCourse";
import MainNav from "../components/MainNav";
import DataTable from "react-data-table-component";
import Sidebar from "../components/Sidebar";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../utils/firebase";
import { Link } from "react-router-dom";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { v4 } from "uuid";
import ReactLoading from "react-loading";
import { useContext } from "react";
import { AuthContext } from "../utils/AuthContext";

function Courses() {
  const [courses, setCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [isFreeCourse, setIsFreeCourse] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(undefined);
  const [videoUrl, setVideoUrl] = useState("");
  const [fsVideo, setFsVideo] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [selectedSource, setSelectedSource] = useState("Firebase Storage");
  const { parsed } = useContext(AuthContext);
  const [courseType, setCourseType] = useState("Course");
  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
  };
  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
  };
  const togglePublish = (course) => {
    const docRef = doc(db, "courses", course.id);
    let data = {
      published: course.published === "yes" ? "no" : "yes",
    };
    updateDoc(docRef, data)
      .then((docRef) => {
        console.log("Value of an Existing Document Field has been updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Create new Course
  const createCourse = (e) => {
    const thumbFileRef = ref(storage, `books/${thumbnail.name + v4()}`);
    setLoading(true);
    e.preventDefault();
    if (courseType === "Course") {
      if (selectedSource === "Firebase Storage") {
        const fsVideoRef = ref(
          storage,
          `courses/${courseName}/${fsVideo.name + v4()}`
        );

        uploadBytes(thumbFileRef, thumbnail).then((snapshot) => {
          const uploadTask = uploadBytesResumable(thumbFileRef, thumbnail);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot);
            },
            (error) => {
              alert(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                let thumbURL = downloadURL;
                console.log(thumbURL, "Thumbnail URL");
                uploadBytes(fsVideoRef, fsVideo).then((snapshot) => {
                  const uploadTask = uploadBytesResumable(fsVideoRef, fsVideo);
                  uploadTask.on(
                    "state_changed",
                    (snapshot) => { },
                    (error) => {
                      alert(error);
                    },
                    () => {
                      getDownloadURL(uploadTask.snapshot.ref).then(
                        (downloadURL) => {
                          console.log(downloadURL, "Video URL");
                          const data = {
                            courseName: courseName,
                            instructor: parsed?.instructorFullname,
                            isFreeCourse:
                              isFreeCourse.charAt(0).toUpperCase() +
                              isFreeCourse.slice(1),
                            price: price,
                            courseType: courseType,
                            shortDescription: shortDescription,
                            thumbnail: thumbURL,
                            instructorId: parsed?.instructorId,
                            videoUrl: downloadURL,
                            published: "no",
                            courseFile: "",
                            source: "Firebase Storage",
                            createdAt: serverTimestamp(),
                          };
                          const colRef = collection(db, "courses");
                          addDoc(colRef, data)
                            .then((docRef) => {
                              console.log(
                                "Document has been added successfully"
                              );
                              alert("Course Created");
                              setShowModal(false);
                              setCourseName("");
                              setIsFreeCourse("");
                              setShortDescription("");
                              setPrice("");
                              setThumbnail(undefined);
                              setFsVideo(undefined);
                              setVideoUrl("");
                              setLoading(false);
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }
                      );
                    }
                  );
                });
              });
            }
          );
        });
      } else {
        uploadBytes(thumbFileRef, thumbnail).then((snapshot) => {
          const uploadTask = uploadBytesResumable(thumbFileRef, thumbnail);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot.progress);
            },
            (error) => {
              alert(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const data = {
                  courseName: courseName,
                  instructor: parsed?.instructorFullname,
                  isFreeCourse:
                    isFreeCourse.charAt(0).toUpperCase() +
                    isFreeCourse.slice(1),
                  price: price,
                  shortDescription: shortDescription,
                  thumbnail: downloadURL,
                  courseType: courseType,

                  published: "no",
                  instructorId: parsed?.instructorId,
                  videoUrl: videoUrl,
                  courseFile: "",
                  createdAt: serverTimestamp(),
                };
                const colRef = collection(db, "courses");
                addDoc(colRef, data)
                  .then((docRef) => {
                    console.log("Document has been added successfully");
                    alert("Course Created");
                    setShowModal(false);
                    setCourseName("");
                    setIsFreeCourse("");
                    setShortDescription("");
                    setPrice("");
                    setThumbnail("");
                    setVideoUrl("");
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            }
          );
        });
      }
    } else {
      if (selectedSource === "Firebase Storage") {
        const fsVideoRef = ref(
          storage,
          `courses/${courseName}/${fsVideo.name + v4()}`
        );

        uploadBytes(thumbFileRef, thumbnail).then((snapshot) => {
          const uploadTask = uploadBytesResumable(thumbFileRef, thumbnail);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot.progress);
            },
            (error) => {
              alert(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                let thumbURL = downloadURL;
                console.log(thumbURL, "Thumbnail URL");
                uploadBytes(fsVideoRef, fsVideo).then((snapshot) => {
                  const uploadTask = uploadBytesResumable(fsVideoRef, fsVideo);
                  uploadTask.on(
                    "state_changed",
                    (snapshot) => { },
                    (error) => {
                      alert(error);
                    },
                    () => {
                      getDownloadURL(uploadTask.snapshot.ref).then(
                        (downloadURL) => {
                          console.log(downloadURL, "Video URL");
                          const data = {
                            courseName: courseName,
                            instructor: parsed?.instructorFullname,
                            isFreeCourse:
                              isFreeCourse.charAt(0).toUpperCase() +
                              isFreeCourse.slice(1),
                            price: price,
                            courseType: courseType,
                            shortDescription: shortDescription,
                            thumbnail: thumbURL,
                            instructorId: parsed?.instructorId,
                            videoUrl: downloadURL,
                            published: "no",
                            courseFile: "",
                            source: "Firebase Storage",
                            createdAt: serverTimestamp(),
                          };
                          const colRef = collection(db, "courses");
                          addDoc(colRef, data)
                            .then((docRef) => {
                              console.log(
                                "Document has been added successfully"
                              );
                              alert("Course Created");
                              setShowModal(false);
                              setCourseName("");
                              setIsFreeCourse("");
                              setShortDescription("");
                              setPrice("");
                              setThumbnail(undefined);
                              setFsVideo(undefined);
                              setVideoUrl("");

                              setLoading(false);
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }
                      );
                    }
                  );
                });
              });
            }
          );
        });
      } else {
        uploadBytes(thumbFileRef, thumbnail).then((snapshot) => {
          const uploadTask = uploadBytesResumable(thumbFileRef, thumbnail);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log(snapshot.progress);
            },
            (error) => {
              alert(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                const data = {
                  courseName: courseName,
                  instructor: parsed?.instructorFullname,
                  isFreeCourse:
                    isFreeCourse.charAt(0).toUpperCase() +
                    isFreeCourse.slice(1),
                  price: price,
                  courseType: courseType,
                  shortDescription: shortDescription,
                  thumbnail: downloadURL,
                  published: "no",
                  instructorId: parsed?.instructorId,
                  videoUrl: videoUrl,
                  courseFile: "",
                  createdAt: serverTimestamp(),
                };
                const colRef = collection(db, "courses");
                addDoc(colRef, data)
                  .then((docRef) => {
                    console.log("Document has been added successfully");
                    alert("Course Created");
                    setShowModal(false);
                    setCourseName("");
                    setIsFreeCourse("");
                    setShortDescription("");
                    setPrice("");
                    setThumbnail("");
                    setVideoUrl("");
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            }
          );
        });
      }
    }
  };

  // Update Course
  const updateCourse = (id) => {
    console.log(id);
  };

  // Delete Course
  const deleteCourse = (id) => {
    const docRef = doc(db, "courses", id);
    deleteDoc(docRef)
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    let userRole = parsed?.userRole;
    if (userRole === "Instructor") {
      let instructorId = parsed?.instructorId;
      const collectionRef = collection(db, "courses");
      const q = query(collectionRef, where("instructorId", "==", instructorId));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setCourses(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            courseName: doc.data().courseName,
            isFreeCourse: doc.data().isFreeCourse,
            price: doc.data().price,
            courseType: doc.data().courseType,
            shortDescription: doc.data().shortDescription,
            thumbnail: doc.data().thumbnail,
            source: doc.data().source,
            published: doc.data().published,
            videoUrl: doc.data().videoUrl,
            instructor: doc.data().instructor,
          }))
        );
      });
      return () => unsubscribe();
    } else {
      const collectionRef = collection(db, "courses");
      const q = query(collectionRef);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        setCourses(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            courseName: doc.data().courseName,
            isFreeCourse: doc.data().isFreeCourse,
            price: doc.data().price,
            courseType: doc.data().courseType,
            shortDescription: doc.data().shortDescription,
            thumbnail: doc.data().thumbnail,
            published: doc.data().published,
            source: doc.data().source,
            videoUrl: doc.data().videoUrl,
            instructor: doc.data().instructor,
          }))
        );
      });
      return () => unsubscribe();
    }
  }, []);
  const addAttactment = (id) => {
    alert(id);
    //    console.log("courseId",id)
    //    console.log("run",run)
  };
  // Filter Search
  const filteredData = courses.filter((course) =>
    course.courseName.toLowerCase().includes(searchVal.toLowerCase())
  );
  const getEnrolledCourses = async (course) => {
    console.log(course)
    const collectionRef = collection(db, 'enrollment');
    const q = query(collectionRef, where("courseId", "==", course.id));
    const snapshot = await getCountFromServer(q);
    let size = snapshot.data().count;
    let text = " Students(s) Enrolled!";
    let fullText = size + text;
    alert(fullText)
  }
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <>
            <div className="courses mx-6">
              <div className="courseHeader flex justify-center flex-col my-6">
                <h1 className="text-3xl py-2 text-primaryColor text-center font-bold tracking-widest">
                  {parsed?.userRole === "Admin" ? "Courses List" : "My Courses"}
                </h1>
                {parsed?.userRole === "Instructor" && (
                  <button
                    className="py-2 px-6 bg-primaryColor rounded-lg text-white mx-auto "
                    onClick={() => setShowModal(true)}
                  >
                    Add New Course
                  </button>
                )}
              </div>
              <div class="overflow-scroll relative">
                <div className=" py-2  focus:outline-0 my-2 rounded-full border-primaryColor/25 px-4 border-2">
                  <input
                    type="text"
                    name="username"
                    placeholder="Search Course By Course name......."
                    className="w-[97%] outline-none"
                    value={searchVal}
                    onChange={(e) => setSearchVal(e.target.value)}
                  />
                  <i className="fas fa-search"></i>
                </div>
                <table
                  className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                  id="table_id"
                >
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Course name
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Instructor
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Thumbnail
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Course Type
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Source
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Update
                      </th>
                      {parsed?.userRole === "Admin" && (
                        <th
                          scope="col"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Published
                        </th>
                      )}
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {" "}
                        Attactment
                      </th>
                      {/* <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        View Quiz
                      </th> */}
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        Show Enrollments
                      </th>
                      <th
                        scope="col"
                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        View Lessons
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((course, key) => {
                      return (
                        <tr key={key}>
                          <td className="py-4 px-6">{course.courseName}</td>
                          {/* <td className='py-4 px-6'>{course.shortDescription = course.shortDescription.substring(0,50)}</td> */}
                          <td className="py-4 px-6">${course.price}</td>
                          <td className="py-4 px-6">{course.instructor}</td>
                          <td className="py-4 px-6">
                            <img
                              src={course.thumbnail}
                              alt=""
                              width={60}
                              height={60}
                            />
                          </td>
                          <td className="py-4 px-6">
                            {course.courseType !== undefined
                              ? course.courseType
                              : "Course"}
                          </td>

                          <td className="py-4 px-6">
                            {course.source !== undefined
                              ? course.source
                              : "Vimeo"}
                          </td>
                          <td className="py-4 px-6">
                            {/* <button  onClick={() => updateCourse(`${course.id}`)} ><i className='fas fa-pen text-black'></i></button> */}
                            <Link
                              to={`update/${course.id}`}
                              className="bg-secondaryColor py-2 px-3 rounded-md"
                            >
                              <i className="fas fa-pen text-black"></i>
                            </Link>
                          </td>
                          {parsed?.userRole === "Admin" && (

                            <td className="py-4 px-6">
                              <button
                                className={`${course.published === "yes"
                                    ? "bg-blue-500"
                                    : "bg-red-400"
                                  } hover:bg-green-300 text-white hover:text-black font-semibold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline`}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Ma Hubtaa Inaad Course-kan Qariso ama Fasaxdo"
                                    )
                                  ) {
                                    togglePublish(course);
                                  }
                                }}
                              >
                                {course.published === "yes" ? "Yes" : "No"}
                              </button>

                            </td>
                          )}
                          <td className="py-4 px-6">
                            <Link
                              to={`/upload/${course.id}/${course.courseName}`}
                              className="bg-green-500 p-2  px-3 rounded-md"
                            >
                              <i className="fas fa-upload text-white"></i>
                            </Link>
                          </td>
                          {/* <td className="py-4 px-6">
                            <Link
                              to={`/quiz/${course.id}`}
                              className="text-black p-2  px-3 rounded-md"
                            >
                              View
                            </Link>
                          </td> */}
                          <td className='py-4 px-6'>
                            <button className="bg-secondaryColor text-black rounded-md p-4"
                              onClick={() => { getEnrolledCourses(course) }}
                            >
                              <i className="fa fa-eye"></i>
                            </button>
                          </td>
                          <td className="py-4 px-6">
                            <Link to={`course/${course.id}`}>
                              <i className="fas fa-eye"></i> See All{" "}
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* Create Course Modal */}
              <>
                {showModal ? (
                  <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                      <div
                        className="fixed inset-0 w-full h-full bg-black opacity-50"
                        onClick={() => setShowModal(false)}
                      ></div>
                      <div className="flex items-center min-h-screen px-4 py-8">
                        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                          <div className="mt-3">
                            <div className="mt-2 text-center sm:ml-4 sm:text-left">
                              <h4 className="text-lg font-medium text-gray-800">
                                Create New Course
                              </h4>
                              <div className="items-center gap-2 mt-3 w">
                                <form>
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Course name *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Course name"
                                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      value={courseName}
                                      onChange={(e) =>
                                        setCourseName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Price *
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Price - Type 0 if course is free"
                                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      value={price}
                                      onChange={(e) => setPrice(e.target.value)}
                                    />
                                  </div>
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Source (Vimeo or Firebase Storage) *
                                    </label>
                                    <div className="flex items-center space-x-4">
                                      {/* Cloud Storage */}
                                      <div>
                                        <input
                                          id="Firebase Storage"
                                          name="source"
                                          type="radio"
                                          className="form-radio text-indigo-600 h-4 w-4"
                                          value="Firebase Storage"
                                          checked={
                                            selectedSource ===
                                            "Firebase Storage"
                                          }
                                          onChange={handleSourceChange}
                                        />
                                        <label
                                          className="ml-2 text-gray-700"
                                          htmlFor="Firebase Storage"
                                        >
                                          Firebase Storage
                                        </label>
                                      </div>
                                      {/* Vimeo */}
                                      <div>
                                        <input
                                          id="Vimeo"
                                          name="source"
                                          type="radio"
                                          className="form-radio text-indigo-600 h-4 w-4"
                                          value="Vimeo"
                                          checked={selectedSource === "Vimeo"}
                                          onChange={handleSourceChange}
                                        />
                                        <label
                                          className="ml-2 text-gray-700"
                                          htmlFor="Vimeo"
                                        >
                                          Vimeo
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {selectedSource === "Vimeo" && (
                                    <div className="form-group flex flex-col">
                                      <label className="text-primaryColor py-2">
                                        Vimeo URL
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Vimeo URL"
                                        className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                        value={videoUrl}
                                        onChange={(e) =>
                                          setVideoUrl(e.target.value)
                                        }
                                      />
                                    </div>
                                  )}
                                  {selectedSource === "Firebase Storage" && (
                                    <div className="form-group flex flex-col">
                                      <label
                                        className="bg-primaryColor text-white text-center cursor-pointer border-2 my-2 rounded-xl border-primaryColor/25 py-2"
                                        htmlFor="Firebase Video"
                                      >
                                        {fsVideo === undefined ? (
                                          <>
                                            Upload Course Video Intro{" "}
                                            <i class="fa-solid fa-cloud-arrow-up"></i>
                                          </>
                                        ) : (
                                          <>{fsVideo.name}</>
                                        )}
                                      </label>
                                      <input
                                        id="Firebase Video"
                                        type="file"
                                        accept="video/*"
                                        placeholder="Upload Video Directly"
                                        className="py-2 hidden  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                        onChange={(e) =>
                                          setFsVideo(e.target.files[0])
                                        }
                                      />
                                    </div>
                                  )}
                                  <div className="form-group flex flex-col mt-2">
                                    <label
                                      className="bg-secondaryColor text-black text-center cursor-pointer border-2 my-2 rounded-xl border-primaryColor/0 py-2"
                                      htmlFor="thumbnail"
                                    >
                                      {thumbnail === undefined ? (
                                        <>
                                          Upload Course Thumbnail Image{" "}
                                          <i class="fa-solid fa-cloud-arrow-up"></i>
                                        </>
                                      ) : (
                                        <>{thumbnail.name}</>
                                      )}
                                    </label>
                                    <input
                                      id="thumbnail"
                                      accept="image/*"
                                      type="file"
                                      placeholder="Course Thumbnail Image"
                                      className="py-2 hidden focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      onChange={(e) =>
                                        setThumbnail(e.target.files[0])
                                      }
                                    />
                                  </div>
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Is Course Free
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Type Yes or No"
                                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      value={isFreeCourse}
                                      onChange={(e) =>
                                        setIsFreeCourse(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Course Type (Course or Language) *
                                    </label>
                                    <div className="flex items-center space-x-4">
                                      {/* Cloud Storage */}
                                      <div>
                                        <input
                                          id="Course"
                                          name="courseType"
                                          type="radio"
                                          className="form-radio text-indigo-600 h-4 w-4"
                                          value="Course"
                                          checked={courseType === "Course"}
                                          onChange={handleCourseTypeChange}
                                        />
                                        <label
                                          className="ml-2 text-gray-700"
                                          htmlFor="Course"
                                        >
                                          Course
                                        </label>
                                      </div>
                                      {/* Vimeo */}
                                      <div>
                                        <input
                                          id="Language"
                                          name="courseType"
                                          type="radio"
                                          className="form-radio text-indigo-600 h-4 w-4"
                                          value="Language"
                                          checked={courseType === "Language"}
                                          onChange={handleCourseTypeChange}
                                        />
                                        <label
                                          className="ml-2 text-gray-700"
                                          htmlFor="Language"
                                        >
                                          Language
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Description
                                    </label>
                                    <textarea
                                      cols="10"
                                      rows="3"
                                      placeholder="Course Description"
                                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      value={shortDescription}
                                      onChange={(e) =>
                                        setShortDescription(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                  {loading ? (
                                    <>
                                      <div className="w-full flex justify-center items-center">
                                        <ReactLoading
                                          type="cylon"
                                          color="#007474"
                                          height={"20%"}
                                          width={"20%"}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        disabled={loading}
                                        className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                        onClick={createCourse}
                                      >
                                        Create Course
                                      </button>
                                      <button
                                        className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                        onClick={() => setShowModal(false)}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
              {/* Add Course Attactment */}
              <></>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Courses;
