import React from 'react'

function Footer() {
  return (
    <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2'>
    <p className='text-center '>Designed & Developed By <a href="https://suhaybka.me" target="_blank" className='font-bold text-primaryColor'>Suhaybka</a></p>
    </div>
  )
}

export default Footer