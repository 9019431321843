import React, { useContext } from "react";

import { AuthContext } from "../utils/AuthContext";
import { parse } from "uuid";
import AdminSidebar from "./AdminSidebar";
import InstructorSidebar from "./InstructorSidebar";
import { Link } from "react-router-dom";

function Sidebar() {
  const { dashUsers } = useContext(AuthContext);
  let parsed = JSON.parse(dashUsers);
  return (
    <div className={parsed?.userRole === "Admin" ? "min-h-full flex bg-primaryColor flex-col" : "min-h-full flex bg-[#007474] flex-col"}>
    <nav className='flex-col  justify-between pt-4 items-center '>
        <div className="logo">
          <Link to="/"><img src="https://razeentc.com/wp-content/uploads/2021/11/razeennn.png" height={30} alt="" /></Link>
        </div>
        {parsed?.userRole === "Admin" ? (
        <>
          <AdminSidebar />
        </>
      ) : (
        <>
          <InstructorSidebar />
        </>
      )}
      </nav>
    </div>
  );
}

export default Sidebar;
