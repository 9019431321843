import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom'
import MainNav from '../components/MainNav'
import Sidebar from '../components/Sidebar'
import { db } from '../utils/firebase';
import ReactLoading from 'react-loading';

   
function Subscriptions() {
    const [subscriptions,setSubscriptions] = useState([]);
    const [totalEarnings,setTotalEarnings] = useState("");
    const [showStudentInfo,setShowStudentInfo] = useState(false);
    const [studentInfo,setStudentInfo] = useState([]);
    const [lastVisible,setLastVisible] = useState("")
    const [loadMore,setLoadMore] = useState(false)
    const[finished,setFinished] = useState(false)
    const [totalSubscriptions,setTotalSubscriptions] = useState("")
     // Get Total Earnings
     useEffect(()=>{
        const getTotalEarnings = async ()=>{
         const q = query(collection(db, "subscriptions"));
         let total = 0;
         const querySnapshot = await getDocs(q);
         querySnapshot.forEach((doc) => {
           // doc.data() is never undefined for query doc snapshots
           let convertedString = {
             "amount":Number(doc.data().amount),
           }
          
           total += convertedString.amount;
         });
         
         console.log("Total",total);
         setTotalEarnings(total);
        }
       getTotalEarnings()
       },[])
    
    useEffect(()=>{
        const getCollectionSize = async () => {
          const querySnapshot = await getDocs(collection(db, 'subscriptions'));
          setTotalSubscriptions(querySnapshot.size)
        };
        
        getCollectionSize();
      },[])
    // LoadMore
  useEffect(()=>{
    if (loadMore === true) {
      console.log("Last Reached",lastVisible);
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'subscriptions'),
          orderBy('payDate', 'desc'),
          startAfter(lastVisible),
          limit(10)
        ),
        snapshot => {
          console.log(snapshot.docs.length);
         if(snapshot.docs.length > 0){
          setSubscriptions(prev => prev.concat(
            snapshot.docs.map(doc => ({
                id:doc.id,
                payDate:doc.data().payDate.toDate().toLocaleDateString('en-US'),
                endDate:doc.data().endDate.toDate().toLocaleDateString('en-US'),
                amount:doc.data().amount,
                userId:doc.data().userId,
                paymentPhone:doc.data().paymentPhone,
                apiType:doc.data().apiType,
              }))
          ));
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setLoadMore(false);
         }
         else{
          setLoadMore(false)
          setFinished(true)
         }
        }
      );

      return ()=>unsubscribe();
    } else setLoadMore(false);
  },[loadMore])
    //   Get Subscriptions
    useEffect(() => {

        const collectionRef = collection(db,'subscriptions');
        const q = query(collectionRef, orderBy("payDate","desc"), limit(10));
        const unsubscribe = onSnapshot(q,snapshot=>{
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        setSubscriptions(
        snapshot.docs.map(doc => ({
        id:doc.id,
        payDate:doc.data().payDate.toDate().toLocaleDateString('en-US'),
        endDate:doc.data().endDate.toDate().toLocaleDateString('en-US'),
        amount:doc.data().amount,
        userId:doc.data().userId,
        paymentPhone:doc.data().paymentPhone,
        apiType:doc.data().apiType,
        }))
        )
        })
        return () => unsubscribe();
        },[])
      // Get Student Data
      const getStudentData = async(enrollment)=>{
        // console.log(enrollment);
        const q = query(collection(db, "students"), where("userId", "==", enrollment.userId));

      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size)
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        setStudentInfo(doc.data())
      });
      }
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
      <MainNav />
        <>
        <div className="courses mx-6">
      <div className="courseHeader flex justify-center flex-col my-6">
      
      <h1 className='text-3xl py-2 text-primaryColor text-center font-bold tracking-widest'>Total Subscriptions {totalSubscriptions} </h1>
      <h1 className='text-3xl py-2 text-thirdColor text-center font-bold tracking-widest'>Total Earnings ${totalEarnings}</h1>
      </div>
      <div className="overflow-scroll relative">
      {/* <div className=' py-2  focus:outline-0 my-2 rounded-full border-primaryColor/25 px-4 border-2'>
    <input type="text" name='username' placeholder='Search Book By Book name.......' className='w-[97%] outline-none' value={searchVal} onChange={e => setSearchVal(e.target.value)} />
    <i className="fas fa-search"></i>
    </div> */}
    <table className="w-full text-sm text-left ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Amount</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Pay Date</th> 
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>End Date</th> 
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Payment Phone</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Payment Type</th> 
            {/* <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Enrolment Date</th> */}
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Show Student Info</th>
              
            </tr>
        </thead>
        <tbody>
        {
      subscriptions.map((subscription,key)=>{
        return(
          <tr key={key}>
        <td className='py-4 px-6'>${subscription.amount}</td>
        <td className='py-4 px-6'>{subscription.payDate}</td>
        <td className='py-4 px-6'>{subscription.endDate}</td>
        <td className='py-4 px-6'>{subscription.apiType === "Manual" ? "Manual" : subscription.paymentPhone}</td>
        <td className='py-4 px-6'>{subscription.apiType}</td>
        <td className='py-4 px-6'>
          <button className="py-1.5 px-3 bg-secondaryColor rounded-md" onClick={()=>{setShowStudentInfo(true); getStudentData(subscription)}}><i className="fas fa-eye text-black" ></i></button>
        </td>
        {/* <td className='py-4 px-6'>{enrollment.enrolmentDate}</td> */}
      </tr>
        )
        })
      }
           
        </tbody>
    </table>
    {
    loadMore ? 
    <>
    <div className="w-full flex justify-center items-center">
                                            <ReactLoading type="cylon" color="#007474" height={'10%'} width={'10%'} />
                                            </div>
    </>
    :
    <>
    <button
      className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
      onClick={()=>{
        if(!finished){
            setLoadMore(true)
          }
      }}
  >
      Load More
  </button>
    </>
   }
</div>


 
<>
            {showStudentInfo ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowStudentInfo(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                           Student Information
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                          <h1 className='text-primaryColor font-semibold my-2 '>Fullname:- {studentInfo.fullname}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>Email:- {studentInfo.email}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>Whatsapp:- {studentInfo.whatsapp}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>User ID:- {studentInfo.userId}</h1>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
        
      </div>
        </>
    </div>
    </div>
    
    </div>
  )
}

export default Subscriptions