import React, { createContext, useEffect, useState } from "react";
import md5 from "md5";
import {
  collection,
  getDocs,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "./firebase";
export const AuthContext = createContext();
export const AuthProvider = ({ children, navigation }) => {
  const [userInfo, setUserInfo] = useState("");
  const [dashUsers, setDashUsers] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parsed, setParsed] = useState({});
  const [noOfStudents, setNoOfStudents] = useState(0);
  const [totalEnrollments, setTotalEnrollments] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [monthlyEnrollments,setMonthlyEnrollments] = useState([])
  const [noOfStudentsByYear, setNoOfStudentsByYear] = useState(0);
  const [totalInstructors, setTotalInstructors] = useState(0);
  const [activeInstructors, setActiveInstructors] = useState(0);
  const [mobileappUsers, setMobileappUsers] = useState(0);
  const [webappUsers, setWebappUsers] = useState(0);
  const [totalCourses,setTotalCourses] = useState(0)
  const [totalSubscriptions,setTotalSubscriptions] = useState(0)
  const [totalSubscriptionsEarnings,setTotalSubscriptionsEarnings] = useState(0)
  const [totalEarningsByInstructor,setTotalEarningsByInstructor] = useState(0)
  const login = async (username, password) => {
    setLoading(true);
    if (username.length > 0 && password.length > 0) {
      let hashedPassword = md5(password);
      let lowerCaseUsername = username.toLowerCase();
      const q = query(
        collection(db, "dashboardusers"),
        where("username", "==", lowerCaseUsername),
        where("password", "==", hashedPassword)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        let dataToPush;
        querySnapshot.forEach((doc) => {
          setLoading(false);
          dataToPush = {
            docId: doc.id,
            userRole: doc.data().userRole,
            username: doc.data().username,
            instructorBio: doc.data().instructorBio,
            instructorFullname: doc.data().instructorFullname,
            instructorImage: doc.data().instructorImage,
            instructorId: doc.data().instructorId,
          };
        });
        let stringified = JSON.stringify(dataToPush);
        setDashUsers(stringified);
        setIsLoggedIn(true);
        setParsed(dataToPush);
        let storeData = localStorage.setItem("dashUsers", stringified);
      } else {
        alert("Username/Password Is Incorrect");
        setLoading(false);
      }
    } else {
      alert("All Fields Are Required");
      setLoading(false);
    }
  };
  const signout = () => {
    setIsLoggedIn(false);
    let removeData = localStorage.removeItem("dashUsers");
    setUserInfo("");
    setDashUsers("");
    setParsed({});
    console.log("Signout");
  };

  const checkIfUserIsLoggedIn = async () => {
    try {
      const value = localStorage.getItem("dashUsers");
      if (value) {
        setIsLoggedIn(true);
        setDashUsers(value);
        let parsed = JSON.parse(value);
        getTotalEnrollments();
        setParsed(parsed);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTotalStudents = async () => {
    const colRef = collection(db, "students");
    const snapshot = await getCountFromServer(colRef);
    setNoOfStudents(snapshot.data().count);
  };
  const getTotalMobileAppUsers = async () => {
    const colRef = collection(db, "students");
    const q = query(colRef, where("platform", "==", "android"));
    const snapshot = await getCountFromServer(q);
    setMobileappUsers(snapshot.data().count);
  };
  const getTotalWebAppUsers = async () => {
    const colRef = collection(db, "students");
    const q = query(colRef, where("platform", "==", "web"));
    const snapshot = await getCountFromServer(q);
    setWebappUsers(snapshot.data().count);
  };
  const getTotalStudentsByYear = async () => {
    const currentYear = new Date().getFullYear();

    const colRef = collection(db, "students");
    const q = query(
      colRef,
      where("registeredAt", ">=", new Date(`${currentYear}-01-01T00:00:00Z`))
    );

    try {
      const querySnapshot = await getDocs(q);
      const count = querySnapshot.size;
      setNoOfStudentsByYear(count);
    } catch (error) {
      console.error("Error getting total students:", error);
    }
  };
  const getTotalInstructors = async () => {
    const colRef = collection(db, "dashboardusers");
    const q = query(colRef, where("userRole", "==", "Instructor"));
    const snapshot = await getCountFromServer(q);
    setTotalInstructors(snapshot.data().count);
  };
  const getTotalCourses = async ()=>{
    const colRef = collection(db, "courses");
    const snapshot = await getCountFromServer(colRef);
    setTotalCourses(snapshot.data().count);
  }
  const getTotalApprovedInstructors = async () => {
    const colRef = collection(db, "dashboardusers");
    const q = query(
      colRef,
      where("userRole", "==", "Instructor"),
      where("activationStatus", "==", "Approved")
    );
    const snapshot = await getCountFromServer(q);
    setActiveInstructors(snapshot.data().count);
  };
  const getMonthlyEnrollments = async () => {
    const colRef = collection(db, 'enrollment');
    const snapshots = await getDocs(colRef);
  
    const monthlyEnrollments = snapshots.docs.reduce((acc, doc) => {
      const enrollmentDate = doc.data().enrolmentDate.toDate();
      const monthKey = enrollmentDate.toISOString().slice(0, 7); // Extract YYYY-MM
  
      // Increment count for the specific month
      acc[monthKey] = (acc[monthKey] || 0) + 1;
  
      return acc;
    }, {});
  
    // Create an array of objects with month and enrollment count
    const chartData = Object.entries(monthlyEnrollments).map(([month, count]) => ({
      month,
      enrollments: count,
    }));
  
    // Add a total object
    chartData.push({ month: 'Total', enrollments: Object.values(monthlyEnrollments).reduce((sum, count) => sum + count, 0) });
    console.log(chartData,"chartData")
    setMonthlyEnrollments(chartData)
  };
  const getTotalEnrollments = () => {
    if (!parsed || Object.keys(parsed).length === 0) return; // Check if parsed is null or empty object
    // console.log(parsed);
    
    console.log(parsed.userRole);
    if (parsed.userRole === "Instructor") {
        const getCollectionSize = async () => {
            const colRef = collection(db, "enrollment");
            const q = query(
                colRef,
                where("instructorId", "==", parsed.instructorId)
            );
            const snapshot = await getCountFromServer(q);
            setTotalEnrollments(snapshot.data().count);
        };

        getCollectionSize();
    } else {
        const getCollectionSize = async () => {
            const colRef = collection(db, "enrollment");
            const snapshot = await getCountFromServer(colRef);
            setTotalEnrollments(snapshot.data().count);
        };

        getCollectionSize();
    }
};

  const getTotalEarnings =async ()=>{
    let userRole = parsed?.userRole;
    let  total = 0;
    
    const colRef = collection(db, "enrollment");

    if (userRole === "Instructor") {
      const q = query(
        colRef,
        where("instructorId", "==", parsed?.instructorId)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let convertedString = {
          "amount":Number(doc.data().amount),
        }
       
        total += convertedString.amount;
      });
      setTotalEarnings(total)
      console.log("total",typeof total)
    } else {
      const querySnapshot = await getDocs(colRef);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let convertedString = {
          "amount":Number(doc.data().amount),
        }
       
        total += convertedString.amount;
      });
      setTotalEarnings(total)
    }
  }
  const getTotalSubscriptions = async() =>{
      const querySnapshot = await getDocs(collection(db, 'subscriptions'));
      setTotalSubscriptions(querySnapshot.size)
  }
  const getTotalSubscriptionsEarnings =async ()=>{
    const q = query(collection(db, "subscriptions"));
    let total = 0;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let convertedString = {
        "amount":Number(doc.data().amount),
      }
     
      total += convertedString.amount;
    });
    
    console.log("Total",total);
    
    setTotalSubscriptionsEarnings(total);
  }


  useEffect(() => {
    checkIfUserIsLoggedIn();
    getTotalStudents();
    getTotalInstructors();
    getTotalApprovedInstructors();
    getTotalMobileAppUsers();
    getTotalWebAppUsers();
    getTotalCourses()
    getTotalSubscriptions()
    // getTotalSubscriptionsEarnings()
    // getMonthlyEnrollments()
  }, []);

  useEffect(() => {
    checkIfUserIsLoggedIn();
  }, [isLoggedIn]);
  return (
    <AuthContext.Provider
      value={{
        userInfo,
        login,
        signout,
        isLoggedIn,
        setIsLoggedIn,
        loading,
        dashUsers,
        parsed,
        setLoading,
        setDashUsers,
        setParsed,
        totalInstructors,
        noOfStudents,
        activeInstructors,
        totalEnrollments,
        totalEarnings,
        noOfStudentsByYear,
        mobileappUsers,
        webappUsers,
        totalCourses,
        monthlyEnrollments,
        totalSubscriptions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
