import React from 'react'
import { Link } from 'react-router-dom'

function NotFoundNav() {
  return (
    <div>
        <nav className='flex justify-between pt-4 items-center '>
        <div className="logo">
          <img src="https://razeentc.com/wp-content/uploads/2021/11/razeennn2.png" height={30} alt="" />
        </div>
        <ul>
          <li className='text-white bg-primaryColor py-2 px-6 rounded-full border-2 border-white hover:bg-white hover:border-2 hover:border-primaryColor hover:text-primaryColor hover:cursor-pointer  text-sm '>
          <Link to="/" className='tracking-wider'>Home</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default NotFoundNav