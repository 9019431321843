
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyA4vHvsB2Zae1ELWWv3WWw8eDu5XFnck9Y",
    authDomain: "razeentc-fdd7e.firebaseapp.com",
    projectId: "razeentc-fdd7e",
    storageBucket: "razeentc-fdd7e.appspot.com",
    messagingSenderId: "997644438630",
    appId: "1:997644438630:web:799f3f4a00b3092ded31a7"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)
export const storage = getStorage(app)
export {db,auth}