import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import MainNav from '../components/MainNav';
import Sidebar from '../components/Sidebar';
import { db } from '../utils/firebase';

function UpdateCourse() {
    const params = useParams();
    const [course,setCourse] = useState([])
    const [noData,setNoData] = useState("");
    const [courseName, setCourseName] = useState("");
  const [instructor, setInstructor] = useState("");
  const [isFreeCourse, setIsFreeCourse] = useState("");
  const [price, setPrice] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
    const [noLessonsFound,setNoLessonsFound] = useState(1);
    
    // Update Course Data 
    const updateCourse =(e)=>{
        e.preventDefault();
        const docRef = doc(db, "courses", params.id);
        // || price.length !==0 || instructor.length !==0 || isFreeCourse.length !==0 || shortDescription.length !==0 || thumbnail.length !==0 || videoUrl.length !==0
        /* 
                
                "price":price,
                "instructor": instructor,
                "isFreeCourse": isFreeCourse,
                "shortDescription":shortDescription,
                "thumbnail":thumbnail,
                "videoUrl":videoUrl,

                
                setInstructor("");
                setIsFreeCourse("");
                setShortDescription("");
                setPrice("");
                setThumbnail("");
                setVideoUrl("");
        */
        // update course name 
        /* 
                "instructor": course.instructor,
                "isFreeCourse": course.isFreeCourse,
                "price":course.price,
                "shortDescription":course.shortDescription,
                "thumbnail":course.thumbnail,
                "videoUrl":course.videoUrl,
        */
        // if(courseName.length === 0 ){
        //     let data = {
        //         "courseName": course.courseName,
        //     }
        //     updateDoc(docRef, data)
        //     .then(docRef => {
        //         console.log("No Fields Updated");
        //         setCourseName("");
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     })
        // }
        // Update Course Name Field
        if(courseName.length !== 0){
            let data = {
                "courseName": courseName,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setCourseName("");
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Price Field
        if(price.length !==0){
            let data = {
                "price": price,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setPrice("");
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Instructor Field
        if(instructor.length !==0){
            let data = {
                "instructor": instructor,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setInstructor("");
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Video URL Field
        if(videoUrl.length !==0){
            let data = {
                "videoUrl": videoUrl,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setVideoUrl("");
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Thumbnail URL Field
        if(thumbnail.length !==0){
            let data = {
                "thumbnail": thumbnail,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setThumbnail("");
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Is Free Course Field
        if(isFreeCourse.length !==0){
            let data = {
                "isFreeCourse": isFreeCourse,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setIsFreeCourse("");
            })
            .catch(error => {
                console.log(error);
            })
        }
         // Update Description Field
         if(shortDescription.length !==0){
            let data = {
                "shortDescription": shortDescription,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setShortDescription("");
            })
            .catch(error => {
                console.log(error);
            })
        }
       
    }
    // Retreive Course Data
    useEffect( () => {
        let id = params.id;
         const docRef = doc(db,'courses',id)
        const unsubscribe =  onSnapshot(docRef,(doc)=>{
            let data = doc.data();
            if(data){
                setCourse(doc.data());
                setNoData(0)
            }
            else{
                setNoData(1)
            }
            })
            
        
        return () => unsubscribe();
    },[])
   
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
      <MainNav />
      <div className="courses mx-6">
     {noData === 1 ? 
     <>
     <h1 className='text-3xl py-4 text-primaryColor text-center font-bold'>No Record Found With The Provided ID</h1>
     </>
     :
     <>
     <div className="courseHeader flex justify-center flex-col my-6">
      
      <h1 className='text-3xl py-2 text-primaryColor text-center font-bold tracking-widest'>{course.courseName}</h1>
      <h1 className='text-lg py-2 text-primaryColor text-center font-bold '>Update Course Info</h1>

      {/* Update Course Form */}
      <div className="mt-2 max-[600px] max-auto text-center sm:text-left">
                                        <div className="items-center gap-2 mt-3 w">
                                            <form>
                                            <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Prev Course name: <small className="font-bold">{course.courseName}</small> </label>
                                          <input type="text" placeholder='Updated Course name' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={courseName} onChange={e => setCourseName(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Price: <small className="font-bold">{course.price}</small> </label>
                                          <input type="number" maxLength={5} placeholder='Updated Price' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={price} onChange={e => setPrice(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Instructor: <small className="font-bold">{course.instructor}</small> </label>
                                          <input type="text" placeholder='Updated Course Instructor' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={instructor} onChange={e => setInstructor(e.target.value)} />
                                      </div>
                                      {/* <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Vimeo URL: <small className="font-bold">{course.videoUrl}</small> </label>
                                          <input type="text" placeholder='Updated Vimeo URL' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={videoUrl} onChange={e => setVideoUrl(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Thumbnail URL: <small className="font-bold">{course.thumbnail}</small> </label>
                                          <input type="text" placeholder='Updated Thumbnail URL' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={thumbnail} onChange={e => setThumbnail(e.target.value)} />
                                      </div> */}
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Is Course Free: <small className="font-bold">{course.isFreeCourse}</small> </label>
                                          <input type="text" placeholder='Updated Type Yes or No' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={isFreeCourse} onChange={e => setIsFreeCourse(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Prev Description: <small className="font-bold">{course.shortDescription}</small> </label>
                                          <textarea  cols="10" rows="3" placeholder='Updated Course Description' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={shortDescription} onChange={e => setShortDescription(e.target.value)}></textarea>
                                      </div>
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={updateCourse}
                                            >
                                                Update Course
                                            </button>
                                            </form>
                                           
                                        </div>
                                    </div>
      </div>
     </>
     }


 
     
      </div>
    </div>
    </div>
    
    </div>
  )
}

export default UpdateCourse