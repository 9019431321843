import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import MainNav from "../components/MainNav";
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import { Link } from "react-router-dom";

function Instructors() {
  const [instructors, setInstructors] = useState([]);
  const [totalInstructors,setTotalInstructors] = useState(0)
  useEffect(() => {
    const getTotalInstructors = async () => {
        const colRef = collection(db, "dashboardusers");
        const q = query(
          colRef,
          where("userRole", "==", "Instructor"),
        );
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot);
      setTotalInstructors(querySnapshot.size);
    };
    getTotalInstructors();
   
  }, []);
  useEffect(() => {
    const collectionRef = collection(db, "dashboardusers");
    const q = query(collectionRef, where("userRole", "==", "Instructor"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setInstructors(
        snapshot.docs.map((doc) => ({
            docId: doc.id,
            userRole: doc.data().userRole,
            activationStatus: doc.data().activationStatus,
            username: doc.data().username,
            instructorBio:doc.data().instructorBio,
            instructorFullname:doc.data().instructorFullname,
            instructorImage:doc.data().instructorImage,
            instructorId:doc.data().instructorId,
            email:doc.data().email,
            phone:doc.data().phone,
        }))
      );
    });
    return () => unsubscribe();
  }, []);
  const deleteInstructor = (id)=>{
    console.log(id);
  }
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <div className="courses mx-6">
            <div className="grid grid-cols-1 sm:grid-cols-1 2xl:grid-cols-1 gap-y-4 my-8">
              <div className="box bg-[#7da0fa] h-40 w-54 rounded-xl shadow-xl">
                <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                  Total Instructors
                </h1>
                <h1 className="text-center text-6xl font-semibold text-white">
                  {totalInstructors}
                </h1>
              </div>
            </div>
            <div className="courseHeader flex justify-center flex-col my-6">
              <h1 className="text-3xl py-2 text-primaryColor text-center font-bold tracking-widest">
                Instructors List
              </h1>
            </div>
            <div className="overflow-scroll relative my-4">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Fullname
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Bio
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {instructors.map((instructor, key) => {
                    return (
                      <tr key={key}>
                        <td className="py-4 px-6">{instructor.instructorFullname}</td>
                        <td className="py-4 px-6">{instructor.email}</td>
                        <td className="py-4 px-6">{instructor.phone}</td>
                        <td className="py-4 px-6">{instructor.instructorBio}</td>
                      
                        
                        <td className={instructor.activationStatus === "Approved" ? "py-4 px-6 text-green-500 font-extrabold " : "py-4 px-6"}>
                          {
                            instructor.activationStatus ==="Approved" ?
                            <>
                              {instructor.activationStatus}
                            </>
                            :
                            <>
                            <Link to={`/instructorapproval/${instructor.docId}`} className="py-2 px-4 text-black bg-secondaryColor text-white rounded-md" >Approve</Link>
                            </> 
                        }
                            </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructors;
