import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { addDoc, collection, getCountFromServer, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter, Timestamp, where } from 'firebase/firestore';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainNav from '../components/MainNav'
import Sidebar from '../components/Sidebar'
import { auth, db } from '../utils/firebase';
import ReactLoading from 'react-loading';
function Students() {
  const [showModal, setShowModal] = useState(false);
  const [students,setStudents] = useState([]);
  const [fullname,setFullname] = useState("");
  const [email,setEmail] = useState("");
  const[noOfStudents,setNoOfStudents] = useState("");
  const [whatsapp,setWhatsapp] = useState("");
  const [password,setPassword] = useState("123456");
  const[enrolledCourses,setEnrolledCourses] = useState(0);
  const [loading,setLoading]= useState(false)
  const [searchVal,setSearchVal] = useState("");
  const [lastVisible,setLastVisible] = useState("")
  const [loadMore,setLoadMore] = useState(false)
  const[finished,setFinished] = useState(false)
  const [input,setInput] = useState([]);
  const [isEmpty,setIsEmpty]= useState(true)
  const [length,setLength] = useState(0);
  const [results,setResults]= useState([])
  const [searchModeOn,setSearchModeOn] = useState(false)


  // LoadMore
  useEffect(()=>{
    if (loadMore === true) {
      console.log("Last Reached",lastVisible);
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'students'),
          orderBy('registeredAt', 'desc'),
          startAfter(lastVisible),
          limit(15)
        ),
        snapshot => {
          console.log(snapshot.docs.length);
         if(snapshot.docs.length > 0){
          setStudents(prev => prev.concat(
            snapshot.docs.map(doc => ({
              id:doc.id,
              fullname:doc.data().fullname,
              email:doc.data().email,
              photoUrl:doc.data().photoURL,
              userId:doc.data().userId,
              whatsapp:doc.data().whatsapp,
              }))
          ));
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setLoadMore(false);
         }
         else{
          setLoadMore(false)
          setFinished(true)
         }
        }
      );

      return ()=>unsubscribe();
    } else setLoadMore(false);
  },[loadMore])
  // Retrieve Students
  useEffect(() => {

    const collectionRef = collection(db,'students');
    const q = query(collectionRef, orderBy("registeredAt","desc"), limit(15));
    const unsubscribe = onSnapshot(q,snapshot=>{
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    setStudents(
    snapshot.docs.map(doc => ({
    id:doc.id,
    fullname:doc.data().fullname,
    email:doc.data().email,
    photoUrl:doc.data().photoURL,
    userId:doc.data().userId,
    whatsapp:doc.data().whatsapp,
    }))
    )
    })
    return () => unsubscribe();
    },[])
    

    useEffect(()=>{
      const getCollectionSize = async () => {
        const colRef = collection(db, "students");
        const snapshot = await getCountFromServer(colRef);
        setNoOfStudents(snapshot.data().count)
      };
      
      getCollectionSize();
    },[])


    const registerStudent = (e)=>{
      e.preventDefault()
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Register Account 
            const user = userCredential.user;
            console.log(userCredential.user);
            const user1 = auth.currentUser;
            let photoURL = "https://www.kindpng.com/picc/m/269-2697881_computer-icons-user-clip-art-transparent-png-icon.png";

                updateProfile(auth.currentUser, {
                    displayName: fullname, 
                    photoURL: photoURL,
                  }).then(() => {
                    // Profile updated!
                    console.log("Fullname Updated");
                    const saveToFireStore =  async () =>{
                        try {
                            const docRef = await addDoc(collection(db, "students"), {
                              fullname: user1.displayName,
                              email: user1.email,
                              photoURL:user1.photoURL,
                              whatsapp:whatsapp,
                              userId:user1.uid,
                              registeredAt:serverTimestamp(),

                            });
                            console.log("Document written with ID: ", docRef.id);
                            alert("Successfully Registered New Student")
                            setEmail("");
                            setFullname("")
                            setWhatsapp("")
                            setShowModal(false)
                          } catch (e) {
                            console.error("Error adding document: ", e);
                          }
                    }
                    saveToFireStore();
                  }).catch((error) => {
                    // An error occurred
                    const errorMessage = error.message;
                    alert(errorMessage)
                    console.log(errorMessage);
                  
                  });
        })
        .catch((error) => {
            const errorMessage = error.message;
            console.log(errorMessage);
            if(errorMessage ==="Firebase: Error (auth/email-already-in-use)."){
                alert("Email already registered")
            }
            else if (errorMessage === "Firebase: Error (auth/invalid-email)."){
                  alert("Invalid Email")
            }
        });
    } //end of registerStudent
    const getEnrolledCourses = async(student)=>{
      const collectionRef = collection(db,'enrollment');
      const q = query(collectionRef,where("userId" , "==",student.userId));
      const querySnapshot = await getDocs(q);
      let size = querySnapshot.size;
      let text = " Course(s) Enrolled!";
      let fullText = size + text;
      alert(fullText)
    }
    // Filter Search
    // const filteredData = students.filter(student =>
    //   student.email.toLowerCase().includes(searchVal.toLowerCase())
    // );

    const searchEmail = async(e)=>{
      setLoading(true)
      setSearchModeOn(true)
      e.preventDefault();
      console.log("searched");
      const q = query(collection(db, "students"), where('email', '>=', searchVal), where('email', '<=', searchVal + '\uf8ff'));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.empty){
        setLength(querySnapshot.size)
        setIsEmpty(true)
        setLoading(false)
      }
      else{
        setIsEmpty(false)
        setLength(querySnapshot.size)
        setLoading(false)
        let results = [];
        querySnapshot.forEach((doc) => {
          results.push(doc.data())
        });
        
        setResults(results)
      }
    }

    // Subscribe 
    const subscribeNow = async (student,plan)=>{
      console.log(plan)
      function addOneYear(date) {
        // Create a new Date object, set to the passed in date
        let newDate = new Date(date);
      
        // Set the month of the new Date object to be one more than the current month
        newDate.setMonth(newDate.getMonth() + 12);
      
        // Return the new Date object
        return newDate;
      }
      function addSixMonths(date) {
        // Create a new Date object, set to the passed in date
        let newDate = new Date(date);
      
        // Set the month of the new Date object to be one more than the current month
        newDate.setMonth(newDate.getMonth() + 6);
      
        // Return the new Date object
        return newDate;
      }
      let today = new Date();
      let endDate;
      let amount;
      if(plan === "6"){
       endDate = addSixMonths(today);
       amount = 12;
      }
      else{
       endDate = addOneYear(today);
       amount = 24;
      }
  
      const colRef = collection(db, "subscriptions");
      // ,where("endDate","<=",maanta)
      const q = query(colRef,where("userId","==",student.userId))
      const querySnapshot = await getDocs(q);
      
      if(querySnapshot.empty){
        console.log("No Previous Subscriptions");
        const data = {
          "amount": amount,
          "apiType":"Manual",
          "paymentStatus":"Paid",
          "payDate":today,
          "paymentPhone":"",
          "endDate":endDate,
          "userId":student.userId
      };
      const colRef = collection(db, "subscriptions");
        addDoc(colRef, data)
    .then(docRef => {
        console.log("Subscription Payment Successful");
        alert("Subscription Successful")
    })
    .catch(error => {
        console.log(error);
    })
      }
      else{
        querySnapshot.forEach((doc) => {
          let endDateFromDb = doc.data().endDate.toDate().toLocaleDateString('en-US');
          var maanta = new Date();
          var dd = String(maanta.getDate()).padStart(2, '0');
          var mm = String(maanta.getMonth() + 1).padStart(2, '0'); 
          var yyyy = today.getFullYear();
          let  todayDate = mm + '/' + dd + '/' + yyyy;
           console.log("End Date",endDateFromDb, " Today",todayDate);
           let endDateToDateObj = new Date(endDateFromDb);
           let todayDateToDateObj = new Date(todayDate);
                               console.log(endDateToDateObj);
                               console.log(todayDateToDateObj);
           var Difference_In_Time = endDateToDateObj.getTime() - todayDateToDateObj.getTime();
                               var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                               console.log(Difference_In_Days);
                               if(Difference_In_Days > 0){
                                 alert("This Student Has Active Subscription With " + Difference_In_Days + " Days")
                               }
                               else{
                                     const data = {
                                          "amount": amount,
                                          "apiType":"Manual",
                                          "paymentStatus":"Paid",
                                          "payDate":today,
                                          "paymentPhone":"",
                                          "endDate":endDate,
                                          "userId":student.userId
                                      };
                                      const colRef = collection(db, "subscriptions");
                                        addDoc(colRef, data)
                                    .then(docRef => {
                                        console.log("Subscription Payment Successful");
                                        alert("Subscription Successful")
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                               }
           });
      }
      // console.log(maanta);
 
    }
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%] overflow-x-auto">
    <div className="content">
        <MainNav />
        <>
        <div className="courses mx-6">
     <div className="flex flex-col justify-center items-center my-2">
     <h1 className='text-3xl py-4 text-primaryColor text-center'>Students</h1>
     <h1 className='text-2xl py-4 text-black bg-secondaryColor my-2 rounded-md px-3 text-center'>Total Registered Students: {noOfStudents}</h1>
      <button className="py-2 px-6 bg-primaryColor rounded-lg text-white mx-auto " onClick={() => setShowModal(true)}>Register New Student</button>
     </div>
      <div className="overflow-x-auto relative">
     {/* Search */}
    <form className="mb-3">
    <div className=' py-2  focus:outline-0 my-2 rounded-full border-primaryColor/25 px-4 border-2'>
    <input type="text" name='username' placeholder='Search Student By Email.....' className='w-[97%] outline-none' value={searchVal} onChange={e => setSearchVal(e.target.value)} />
    <button
    onClick={(e)=>{
      e.preventDefault()
      if(searchVal){
        setSearchModeOn(false)
        setSearchVal("");
      }
    }}
    >
      {
        searchModeOn ? 
        <>
        <i className="fas fa-close"></i>
        </>
        :
        <>
        <i className="fas fa-search"></i>
        </>
      }
    </button>
    
    </div>
    <button
      className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
      onClick={searchEmail}
  >
      Search <i className="fas fa-search"></i>
  </button>
    </form>
    {
      searchModeOn && (
        <div className="flex flex-col  w-full ">
          <h1 className='text-center font-semibold text-md text-primaryColor'>Showing Search Results</h1>
          <h1 className='text-center font-semibold text-md text-primaryColor'>Searched Query:- {searchVal}</h1>
          <h1 className='text-center font-semibold text-md text-primaryColor'>Total Records:- {length}</h1>
          </div>
      )
    }
    <table className="w-full text-sm text-left text-gray-900 " id="table">
        <thead className="text-xs text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 ">
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Fullname</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Email</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Show Enrolled Courses</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Enrol a Course</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Subscription</th>
            </tr>
        </thead>
        <tbody>
       {
        !searchModeOn ? 
        <>
        <h1 className='text-right font-semibold my-2 text-2xl text-primaryColor'>All Students</h1>
        {
          students.map(student=>{
        return(
          <tr>
        <td className='py-4 px-6'>{student.fullname}</td>
        <td className='py-4 px-6'>{student.email}</td>
        <td className='py-4 px-6'>
        <button className="bg-secondaryColor text-black rounded-md p-4"
        onClick={()=>{getEnrolledCourses(student)}}
        >
          <i className="fa fa-eye"></i>
        </button>
        </td>
        <td className='py-4 px-6'>
          <Link to={`/enrol/${student.userId}/${student.email}`}>Choose a course</Link>
        </td>
        <td className='py-4 px-6'>
        <div className="flex flex-col space-y-2">
        <button className="p-2 bg-primaryColor text-white rounded-md" onClick={() =>{if ((window.confirm('Are you sure you want to pay the subscription of 6 Months?'))){ subscribeNow(student,"6")} }} >6 Months</button>
        <button className="p-2 bg-secondaryColor text-black rounded-md" onClick={() =>{if ((window.confirm('Are you sure you want to pay the subscription of 1 Year?'))){ subscribeNow(student,"1")} }} >1 Year</button>
        </div>
        </td>
      </tr>
        )
        })
      }
        </>
        :
        <>
        {
          length > 0 ?
          <>
         
          {results.map((result,key) => (
            <tr>
        <td className='py-4 px-6'>{result.fullname}</td>
        <td className='py-4 px-6'>{result.email}</td>
        <td className='py-4 px-6'>
        <button className="bg-secondaryColor text-black rounded-md p-4"
        onClick={()=>{getEnrolledCourses(result)}}
        >
          <i className="fa fa-eye"></i>
        </button>
        </td>
        <td className='py-4 px-6'>
          <Link to={`/enrol/${result.userId}/${result.email}`}>Choose a course</Link>
        </td>
        <td className='py-4 px-6'>
        <div className="flex flex-col space-y-2">
        <button className="p-2 bg-primaryColor text-white rounded-md" onClick={() =>{if ((window.confirm('Are you sure you want to pay the subscription of 6 Months?'))){ subscribeNow(result,"6")} }} >6 Months</button>
        <button className="p-2 bg-secondaryColor text-black rounded-md" onClick={() =>{if ((window.confirm('Are you sure you want to pay the subscription of 1 Year?'))){ subscribeNow(result,"1")} }} >1 Year</button>
        </div>

        </td>
      </tr>
      ))}
          </>
          :
          <>
          <h1 className='text-right font-semibold my-2 text-md text-red-700'>No Records Found</h1>
            
          </>
        }
        </>

       }
           
        </tbody>
    </table>

    {/* Load More */}
   {
    !searchModeOn && (
      <div className="my-4">
   {
    loadMore ? 
    <>
    <div className="w-full flex justify-center items-center">
                                            <ReactLoading type="cylon" color="#007474" height={'20%'} width={'20%'} />
                                            </div>
    </>
    :
    <>
    <button
      className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
      onClick={()=>{
        if(!finished){
            setLoadMore(true)
          }
      }}
  >
      Load More
  </button>
    </>
   }
   </div>
    )
   }


</div>

{/* Create Course Modal */}
<>
            {showModal ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                            Create New Course
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                            <form>
                                            <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Student fullname</label>
                                          <input type="text" placeholder='Student fullname' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={fullname} onChange={e => setFullname(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Email</label>
                                          <input type="text"  placeholder='Email' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={email} onChange={e => setEmail(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Whatsapp No</label>
                                          <input type="text" placeholder='Student Whatsapp No' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={whatsapp} onChange={e => setWhatsapp(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Default Password For Student Is: <span className='font-bold'>123456</span></label>
                                      </div>
                                     
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={registerStudent}
                                            >
                                                Register Student
                                            </button>
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                                onClick={() =>
                                                    setShowModal(false)
                                                }
                                            >
                                                Cancel
                                            </button>
                                            </form>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
     
      </div>
        </>
    </div>
    </div>
    
    </div>
  )
}

export default Students