import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import NotFoundNav from '../components/NotFoundNav'

function NotFound() {
  return (
    <div className='container mx-auto max-h-screen max-w-2xl'>
    <NotFoundNav />
    <div className="content  my-6 max-h-screen flex max-[650px]:flex-col  shadow-2xl rounded-2xl text-white">
    <div className="left flex bg-primaryColor flex-col  flex-1 py-10 px-4 justify-center">
    <h1 className='text-3xl pt-10 font-bold tracking-widest'>Uh Oh...</h1>
    <h1 >We can't find the page you're looking for.</h1>
    <div className="buttons flex flex-row">
    <button className='py-2 px-6 bg-white mr-2 text-primaryColor my-4 rounded-full'> <Link to="/" className='tracking-wider'>Home</Link></button>
    <button className='py-2 px-6 bg-white mr-2 text-primaryColor my-4 rounded-full'> <Link to="/courses" className='tracking-wider'>All Courses</Link></button>
    </div>
    </div>
    <div className="right flex-1 ">
    <img src="https://images.pexels.com/photos/6333503/pexels-photo-6333503.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className='max-[650px]:w-full object-cover rounded-2xl  max-[650px]:h-2/5' alt="" />
    </div>
    </div>
    <div className="footer max-[650px]:hidden">
    
    <Footer />
    </div>
    </div>
  )
}

export default NotFound