import React, { useContext, useState } from "react";
import Sidebar from "../components/Sidebar";
import MainNav from "../components/MainNav";
import { AuthContext } from "../utils/AuthContext";
import { db, storage } from "../utils/firebase";
import { v4 } from "uuid";
import md5 from "md5";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import ReactLoading from "react-loading";
function Profile() {
  const { parsed, setLoading, loading, setDashUsers, setParsed } =
    useContext(AuthContext);
  const [instructorFullname, setInstructorFullname] = useState(
    parsed?.instructorFullname
  );
  const [instructorBio, setInstructorBio] = useState(parsed?.instructorBio);
  const [password,setPassword] = useState("");
  const [isEditReady, setIsEditReady] = useState(false);
  const [instructorImage, setInstructorImage] = useState("");
  const [showPassword,setShowPassword] = useState(false)
  const updateProfile = async (e) => {
    e.preventDefault();
   
      setLoading(true);
   
   if(!instructorImage){
    let userId = parsed.docId;
    const docRef = doc(db, "dashboardusers", userId);
    if(password.length > 5){
      let data = {
        instructorBio: instructorBio,
        instructorFullname: instructorFullname,
        password:md5(password)
      };
      updateDoc(docRef, data)
        .then((docRef) => {
          let dataToPush = {
            instructorBio: instructorBio,
            instructorFullname: instructorFullname,
            instructorImage: parsed?.instructorImage,
            username: parsed?.username,
            docId: parsed?.docId,
            instructorId:parsed?.instructorId,
            userRole: "Instructor",
          };
          let stringified = JSON.stringify(dataToPush);
          setDashUsers(stringified);
          setParsed(dataToPush);
          let storeData = localStorage.setItem("dashUsers", stringified);
          setLoading(false);
          setIsEditReady(false);
          setPassword("")
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else{
      let data = {
        instructorBio: instructorBio,
        instructorFullname: instructorFullname,
      };
      updateDoc(docRef, data)
        .then((docRef) => {
          let dataToPush = {
            instructorBio: instructorBio,
            instructorFullname: instructorFullname,
            instructorImage: parsed?.instructorImage,
            username: parsed?.username,
            docId: parsed?.docId,
            instructorId:parsed?.instructorId,
            userRole: "Instructor",
          };
          let stringified = JSON.stringify(dataToPush);
          setDashUsers(stringified);
          setParsed(dataToPush);
          let storeData = localStorage.setItem("dashUsers", stringified);
          setLoading(false);
          setIsEditReady(false);
          setPassword("")
        })
        .catch((error) => {
          console.log(error);
        });
    }
   }
   else{
    let userId = parsed.docId;
    const docRef = doc(db, "dashboardusers", userId);
    if(password.length > 5){
      const instructorImageRef = ref(
        storage,
        `instructor/${instructorImage.name + v4()}`
      );
      uploadBytes(instructorImageRef, instructorImage).then((snapshot) => {
        const uploadTask = uploadBytesResumable(
          instructorImageRef,
          instructorImage
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              let data = {
                instructorBio: instructorBio,
                instructorFullname: instructorFullname,
                instructorImage: downloadURL,
                password:md5(password)
              };
              updateDoc(docRef, data)
                .then((docRef) => {
                  let dataToPush = {
                    instructorBio: instructorBio,
                    instructorFullname: instructorFullname,
                    instructorImage: downloadURL,
                    username: parsed?.username,
                    instructorId:parsed?.instructorId,
                    docId: parsed?.docId,
                    userRole: "Instructor",
                  };
                  let stringified = JSON.stringify(dataToPush);
                  setDashUsers(stringified);
                  setParsed(dataToPush);
                  let storeData = localStorage.setItem("dashUsers", stringified);
                  // console.log("Value of an Existing Document Field has been updated");
                  setLoading(false);
                  setIsEditReady(false);
                  setPassword("")
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        );
      });
    }
    else{
      const instructorImageRef = ref(
        storage,
        `instructor/${instructorImage.name + v4()}`
      );
      uploadBytes(instructorImageRef, instructorImage).then((snapshot) => {
        const uploadTask = uploadBytesResumable(
          instructorImageRef,
          instructorImage
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              let data = {
                instructorBio: instructorBio,
                instructorFullname: instructorFullname,
                instructorImage: downloadURL,
  
              };
              updateDoc(docRef, data)
                .then((docRef) => {
                  let dataToPush = {
                    instructorBio: instructorBio,
                    instructorFullname: instructorFullname,
                    instructorImage: downloadURL,
                    username: parsed?.username,
                    docId: parsed?.docId,
                    instructorId:parsed?.instructorId,
                    userRole: "Instructor",
                  };
                  let stringified = JSON.stringify(dataToPush);
                  setDashUsers(stringified);
                  setParsed(dataToPush);
                  let storeData = localStorage.setItem("dashUsers", stringified);
                  // console.log("Value of an Existing Document Field has been updated");
                  setLoading(false);
                  setPassword("")
                  setIsEditReady(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        );
      });
    }
   }
    
  };
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
       {
        parsed?.userRole === "Instructor" ? 
        <>
        <div className="content">
          <MainNav />
          <br />
          <br />
          {!isEditReady ? (
            <>
              <div className="max-w-lg mx-auto bg-gray-100 p-6 shadow-lg rounded-lg overflow-hidden flex flex-col items-center justify-center">
                <img
                  className="block mx-auto sm:mx-0 sm:flex-shrink-0 h-24 sm:h-16 rounded-full"
                  src={parsed?.instructorImage}
                  alt="Instructor Profile"
                />
                <div className="sm:flex sm:items-center px-6 py-4 space-y-6">
                  <div className="mt-4 sm:mt-0 sm:ml-4 text-center">
                    <h1 className="text-lg font-bold text-gray-900 capitalize">
                      {parsed?.instructorFullname}
                    </h1>

                    <p className="text-sm font-medium text-gray-600">
                      {parsed?.userRole}
                    </p>

                    <br />
                    <br />
                    <p className="text-sm text-gray-700 mt-2">
                      {parsed?.instructorBio}
                    </p>
                    <br />

                    <button
                      className="py-2 px-6 rounded-xl bg-secondaryColor"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditReady(true);
                      }}
                    >
                      <i className="fas fa-pen"></i> Edit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="max-w-lg mx-auto bg-gray-100 p-6  shadow-lg rounded-lg overflow-hidden flex flex-col items-center justify-center">
                <div className="form-group flex flex-col">
                  <label className="text-primaryColor py-2">
                    Instructor Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    placeholder="InstructorImage"
                    className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                    onChange={(e) => setInstructorImage(e.target.files[0])}
                  />
                </div>
                <div className="flex flex-col px-6 py-4 space-y-6">
                  <form className="w-full  ">
                    <div className="form-group flex flex-col  px-28">
                      <label className="text-primaryColor py-2">
                        Instructor Fullname
                      </label>
                      <input
                        type="text"
                        placeholder="Instructor Fullname"
                        className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2 "
                        value={instructorFullname}
                        onChange={(e) => setInstructorFullname(e.target.value)}
                      />
                    </div>

                   <div className="form-group flex flex-col  px-28">
                      <label className="text-primaryColor py-2">
                        Change Password
                      </label>
                     <div className="flex items-center rounded-xl border-primaryColor/25 px-4 border-2 ">
                     <input
                        type={showPassword ? "text" :"password"}
                        placeholder="Min Length Is 6 Chars"
                        className="py-2  focus:outline-0  "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button onClick={(e)=>{
                        e.preventDefault()
                        setShowPassword(!showPassword)
                      }}><i class="fa-solid fa-eye"></i></button>
                     </div>
                    </div>

                    <div className="form-group flex flex-col">
                      <label className="text-primaryColor py-2">
                        Instructor Short Bio
                      </label>
                      <textarea
                        cols="10"
                        rows="5"
                        placeholder="Instructor Short Bio"
                        className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                        value={instructorBio}
                        onChange={(e) => setInstructorBio(e.target.value)}
                      ></textarea>
                    </div>
                  </form>
                  <br />

                  {loading ? (
                    <>
                      <div className="w-full flex justify-center items-center">
                        <ReactLoading
                          type="cylon"
                          color="#007474"
                          height={"20%"}
                          width={"20%"}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        className="py-2 px-6 rounded-xl bg-green-600 text-white"
                        onClick={updateProfile}
                        disabled={
                          instructorBio.length < 5 ||
                          instructorFullname.length < 5 ||
                          loading
                        }
                      >
                        <i className="fas fa-pen"></i> Update
                      </button>
                      <button
                    className="py-2 px-6 rounded-xl bg-red-600 text-white"
                    onClick={() => setIsEditReady(false)}
                  >
                    <i className="fas fa-close"></i> Cancel
                  </button>
                    </>
                  )}
                 
                </div>
              </div>
            </>
          )}
        </div>
        </>
        :
        <>
         <div>
         <MainNav />
          <br />
          <br />
          <br />
          <br />
         <div className="flex items-center justify-center  h-full">
            <h1 className="text-center text-4xl text-primaryColor font-extrabold">
              You're An Admin
            </h1>
          </div>
         </div>
        </>
       }
      </div>
    </div>
  );
}

export default Profile;
