import React, { useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Books from "../Pages/Books";
import Courses from "../Pages/Courses";
import Enrol from "../Pages/Enrol";
import Enrollments from "../Pages/Enrollments";
import Home from "../Pages/Home";
import Lessons from "../Pages/Lessons";
import Login from "../Pages/Login";
import NotFound from "../Pages/NotFound";
import SingleCourse from "../Pages/SingleCourse";
import Students from "../Pages/Students";
import UpdateBook from "../Pages/UpdateBook";
import UpdateCourse from "../Pages/UpdateCourse";
import Upload from "../Pages/Upload";
import Test from "../Pages/Test";
import { AuthContext } from "../utils/AuthContext";
import Email from "../Pages/Email";
import Subscriptions from "../Pages/Subscriptions";
import EnrollmentData from "../Pages/EnrollmentData";
import Profile from "../Pages/Profile";
import Instructors from "../Pages/Instructors";
import InstructorApproval from "../Pages/InstructorApproval";
import Quiz from "../Pages/Quiz";
import Questions from "../Pages/Questions";
import NotAllowed from "../Pages/NotAllowed";
import PrivateRoute from "./PrivateRoute";
import ChangePassword from "../Pages/ChangePassword";
import EnrollmentsReport from "../Pages/EnrollmentsReport";
import YearlyReport from "../Pages/YearlyReport";
const mainRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home  allowedRoles={["instructor","admin"]} />,
  },
  {
    path: "/courses",
    element: <Courses />,
  },
  {
    path: "/instructors",
    element: <Instructors />,
  },
  {
    path: "/changepassword",
    element: <ChangePassword />,
  },
  {
    path: "/enrollmentsreport",
    element: <EnrollmentsReport />,
  },
  {
    path: "/yearlyreport",
    element: <YearlyReport />,
  },
  {
    path: "/instructorapproval/:id",
    element: <InstructorApproval />,
  },
  {
    path: "/enrol/:id/:email",
    element: <Enrol />,
  },
  {
    path: "/upload/:id/:courseName",
    element: <Upload />,
  },
  {
    path: "/enrollmentdata/:id/:courseName/:price",
    element: <EnrollmentData />,
  },
  {
    path: "/courses/course/:id",
    element: <SingleCourse />,
  },
  {
    path: "/quiz/:id",
    element: <Quiz />,
  },
  {
    path: "/quiz/:courseId/:quizId",
    element: <Questions />,
  },
  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/subscriptions",
    element: <Subscriptions />,
  },
  {
    path: "/email",
    element: <Email />,
  },
  {
    path: "/courses/update/:id",
    element: <UpdateCourse />,
  },
  {
    path: "/books/update/:id",
    element: <UpdateBook />,
  },
  {
    path: "/courses/course/:id/lessons/:id",
    element: <Lessons />,
  },
  {
    path: "/students",
    element: <Students />,
  },
  {
    path: "/enrollments",
    element: <Enrollments />,
  },
  {
    path: "/books",
    element: <Books />,
  },
  {
    path: "/notallowed",
    element: <NotAllowed />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
const authRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <Login />,
  },
]);
function Main() {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <>
         {!isLoggedIn ? (
        <>
          <RouterProvider router={authRouter} />
        </>
      ) : (
        <>
          <RouterProvider router={mainRouter} />
        </>
      )}
    </>
  );
}

export default Main;
