import React, { useContext, useEffect, useState } from "react";
import Footer from "../components/Footer";
import ReactLoading from "react-loading";
import { AuthContext } from "../utils/AuthContext";
function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const {login ,isLoggedIn,loading} = useContext(AuthContext);
  return (
    <div className="bg-gray-300 min-h-screen flex flex-col justify-center relative">
      <div className="shadow-lg p-16 rounded-2xl bg-white mx-auto container content-center max-w-2xl ">
        <>
          <h1 className="text-3xl text-primaryColor text-center font-bold py-2">
            Razeen App Dashboard {isLoggedIn}
          </h1>
          <h5 className="text-md text-third text-center mb-6">
            Sign In With Your Credentials
          </h5>
          <form>
            <div className="form-group flex flex-col">
              <label
                htmlFor="Username"
                className="text-primaryColor font-bold py-2"
              >
                Username
              </label>
              <input
                type="text"
                name="username"
                placeholder="Username"
                className="py-2  focus:outline-0 my-2 rounded-full border-primaryColor/25 px-4 border-2"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group flex flex-col">
              <label
                htmlFor="Username"
                className="text-primaryColor font-bold py-2"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="py-2  focus:outline-0 my-2 rounded-full border-primaryColor/25 px-4 border-2"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group flex flex-col">
            {
              loading ? 
              <>
              <button className="w-full rounded-full mt-2 p-2.5 flex-1  text-white bg-primaryColor outline-none">
              Loading.....
              </button>
              </>
              :
              <>
              <button
                className="w-full rounded-full mt-2 p-2.5 flex-1  text-white bg-primaryColor outline-none"
                onClick={(e)=>{
                  e.preventDefault()
                   login(username,password)
                }}
              >
                Login
              </button>
              </>
            }
            </div>
          </form>
        </>
      </div>

      <Footer />
    </div>
  );
}

export default Login;
