import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import ReactLoading from 'react-loading'
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainNav from "../components/MainNav";
import Sidebar from "../components/Sidebar";
import { db } from "../utils/firebase";

function EnrollmentData() {
  const params = useParams();
  const [enrollments, setEnrollments] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [showStudentInfo, setShowStudentInfo] = useState(false);
  const [studentInfo, setStudentInfo] = useState([]);
  const [lastVisible, setLastVisible] = useState("");
  const [loadMore, setLoadMore] = useState(false);
  const [finished, setFinished] = useState(false);
  const [commission,setCommission]= useState(0);
  const [netRevenue,setNetRevenue] = useState(0)
  const [totalEnrollments, setTotalEnrollments] = useState("");
  // Get Total Earnings
  useEffect(() => {
    const getTotalEarnings = async () => {
      const q = query(
        collection(db, "enrollment"),
        where("isCourseFree", "==", "No"),
        where("courseId", "==", params.id),
        where("userRole", "!=", "Testing")
      );
      let total = 0;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let convertedString = {
          amount: Number(doc.data().amount),
        };

        total += convertedString.amount;

      });

      console.log("Total", total);
      let comissionInNumbers = (total / 100) * 30;
      let rounded = comissionInNumbers.toFixed(2)
      console.log(rounded,"Commission");
      setCommission(rounded)
      setTotalEarnings(total);
      let netRevenue = total - rounded;
      console.log(netRevenue,"Net Income")
      setNetRevenue(netRevenue)
    };
    getTotalEarnings();
  }, []);
  useEffect(() => {
    const getCollectionSize = async () => {
      const colRef = collection(db, "enrollment");
      const q = query(
        colRef,
        where("isCourseFree", "==", "No"),
        where("courseId", "==", params.id),
        where("userRole", "!=", "Testing")
      );
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.size);
      setTotalEnrollments(querySnapshot.size);
    };

    getCollectionSize();
  }, []);
   // LoadMore
   useEffect(()=>{
    if (loadMore === true) {
      console.log("Last Reached",lastVisible);
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'enrollment'),
          where("isCourseFree", "==", "No"),
          where("courseId", "==", params.id),
          where("userRole", "!=", "Testing"),
          startAfter(lastVisible),
          limit(10)
        ),
        snapshot => {
          console.log(snapshot.docs.length);
         if(snapshot.docs.length > 0){
          setEnrollments(prev => prev.concat(
            snapshot.docs.map(doc => ({
              id: doc.id,
              courseName: doc.data().courseName,
              courseId: doc.data().courseId,
              instructor: doc.data().instructor,
              isFreeCourse: doc.data().isFreeCourse,
              userId: doc.data().userId,
              apiType: doc.data().apiType,
              amount: doc.data().amount,
              }))
          ));
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setLoadMore(false);
         }
         else{
          setLoadMore(false)
          setFinished(true)
         }
        }
      );

      return ()=>unsubscribe();
    } else setLoadMore(false);
  },[loadMore])
  // Get Enrollments
  useEffect(() => {
    const collectionRef = collection(db, "enrollment");
    const q = query(collectionRef,
      where("isCourseFree", "==", "No"),
      where("courseId", "==", params.id),
      where("userRole", "!=", "Testing"), limit(10));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setEnrollments(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          courseName: doc.data().courseName,
          courseId: doc.data().courseId,
          instructor: doc.data().instructor,
          isFreeCourse: doc.data().isFreeCourse,
          userId: doc.data().userId,
          apiType: doc.data().apiType,
          paymentPhone: doc.data().paymentPhone,
          amount: doc.data().amount,
        }))
      );
    });
    return () => unsubscribe();
  }, []);
  // Get Student Data
  const getStudentData = async(enrollment)=>{
    // console.log(enrollment);
    const q = query(collection(db, "students"), where("userId", "==", enrollment.userId));

  const querySnapshot = await getDocs(q);
  console.log(querySnapshot.size)
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    setStudentInfo(doc.data())
  });
  }
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <div className="courses mx-6">
         <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-y-4 my-8">
          <div className="box bg-[#7da0fa] h-40 w-54 rounded-xl shadow-xl">
          <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                Total Enrollments
              </h1>
              <h1 className="text-center text-6xl font-semibold text-white">
              {totalEnrollments}
              </h1>
          </div>
          <div className="box bg-[#4747a1] h-40 w-54 rounded-xl shadow-xl ml-4">
          <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                Total Earnings
              </h1>
              <h1 className="text-center text-6xl font-semibold text-white">
              ${totalEarnings}
              </h1>
          </div>
          <div className="box bg-[#f3797e] h-40 w-54 rounded-xl shadow-xl">
          <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                Razeen Commission
              </h1>
              <h1 className="text-center text-6xl font-semibold text-white">
              30% - ${commission}
              </h1>
          </div>
          <div className="box bg-green-500 h-40 w-54 rounded-xl shadow-xl ml-4">
          <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                Net Income
              </h1>
              <h1 className="text-center text-6xl font-semibold text-white">
              ${netRevenue}
              </h1>
          </div>
         </div>
            <div className="courseHeader flex justify-center flex-col my-6">
             
              <div className="text-gray-600 text-center justify-around flex font-semibold">
                <h1>Course Name: {params.courseName}</h1>
                <h1>Course Price: ${params.price}</h1>
              </div>
            </div>
            <div className="overflow-scroll relative">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Course name
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Payment Type
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Payment Phone
                    </th>
                  
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Instructor
                    </th>
                    <th
                      scope="col"
                      className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Show Student Info
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    enrollments.map((enrollment,key)=>{
                      return (
                        <tr key={key}>
        <td className='py-4 px-6'>{enrollment.courseName}</td>
        <td className='py-4 px-6'>${enrollment.amount}</td>
        <td className='py-4 px-6'>{enrollment.apiType}</td>
        <td className='py-4 px-6'>{enrollment.apiType === "Manual" ? "Manual" : enrollment.paymentPhone}</td>
        <td className='py-4 px-6'>{enrollment.instructor}</td>
        <td className='py-4 px-6'>
          <button className="py-1.5 px-3 bg-secondaryColor rounded-md" onClick={()=>{setShowStudentInfo(true); getStudentData(enrollment)}}><i className="fas fa-eye text-black" ></i></button>
        </td>
        {/* <td className='py-4 px-6'>{enrollment.enrolmentDate}</td> */}
      </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              {
    loadMore ? 
    <>
    <div className="w-full flex justify-center items-center">
                                            <ReactLoading type="cylon" color="#007474" height={'10%'} width={'10%'} />
                                            </div>
    </>
    :
    <>
    <button
      className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
      onClick={()=>{
        if(!finished){
            setLoadMore(true)
          }
      }}
  >
      Load More
  </button>
    </>
   }
            </div>

            <>
            {showStudentInfo ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowStudentInfo(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                           Student Information
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                          <h1 className='text-primaryColor font-semibold my-2 '>Fullname:- {studentInfo.fullname}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>Email:- {studentInfo.email}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>Whatsapp:- {studentInfo.whatsapp}</h1>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentData;
