import {
  collection,
  getCountFromServer,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import MainNav from "../components/MainNav";
import Sidebar from "../components/Sidebar";
import { db } from "../utils/firebase";
import ReactLoading from "react-loading";
import { useContext } from "react";
import { AuthContext } from "../utils/AuthContext";
import { v4 } from "uuid";
import UserRoleCheck from "../components/UserRoleCheck";
function Home({ allowedRoles }) {
  const [isModal, setIsModal] = useState(false);
  const {
    parsed,
    totalInstructors,
    activeInstructors,
    noOfStudents,
    totalEnrollments,
    totalEarnings,
  } = useContext(AuthContext);

  if (isModal) {
    return <UserRoleCheck />;
  } else {
    return (
      <div className=" min-h-screen flex">
        <div className="left flex-[1_1_0%]">
          <Sidebar />
        </div>
        <div className="right flex-[3_3_0%]">
          <div className="content">
            <MainNav />
            <div className="head mx-6 my-4">
              <img
                src="https://images.pexels.com/photos/4143800/pexels-photo-4143800.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                className="h-48 w-full object-cover rounded-3xl"
                alt=""
              />
              <h1 className="text-3xl font-semibold py-2 tracking-widest text-primaryColor text-center">
                Welcome To Razeen Platform
              </h1>
            </div>
            <div className="dashboard flex justify-center">
              {parsed?.userRole === "Admin" && (
                <>
                  <div className="box mx-6 shadow-lg rounded-2xl bg-cyan-400 p-10 text-center text-black semi-bold">
                    <h1 className="text-6xl pb-6">{!noOfStudents ? "...":noOfStudents}</h1>
                    <h1>
                      <i className="fas fa-users"></i> Students{" "}
                    </h1>
                  </div>
                  <div className="box mx-6 shadow-lg rounded-2xl bg-teal-300 p-10 text-center text-black semi-bold">
                    <h1 className="text-6xl pb-6">{!totalInstructors ? "...":totalInstructors}</h1>
                    <h1>
                      <i className="fas fa-users"></i> Total Instructors{" "}
                    </h1>
                  </div>
                  <div className="box mx-6 shadow-lg rounded-2xl bg-orange-500 p-10 text-center text-white semi-bold">
                    <h1 className="text-6xl pb-6">{!activeInstructors ? "..." : activeInstructors}</h1>
                    <h1>
                      <i className="fas fa-users"></i> Active Instructors{" "}
                    </h1>
                  </div>
                </>
              )}
              <div className="box mx-6 shadow-lg rounded-2xl bg-[#3c3c3c] p-10 text-center text-white semi-bold">
                <h1 className="text-6xl pb-6">{!totalEnrollments ? "..." :totalEnrollments}</h1>
                <h1>
                  <i className="fas fa-book"></i> Total Enrollment(s)
                </h1>
              </div>
              {parsed?.userRole === "Instructor" && (
                <div className="box mx-6 shadow-lg rounded-2xl bg-secondaryColor p-10 text-center text-black semi-bold">
                  <h1 className="text-6xl pb-6">{!totalEarnings ? "...":"$" +totalEarnings}</h1>
                  <h1>
                    <i className="fas fa-dollar-sign"></i> Total Earning(s)
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
