import React, { useState } from 'react'
import MainNav from '../components/MainNav'
import Sidebar from '../components/Sidebar'
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';

function Email() {
    const [emails,setEmails] = useState([])
    const [totalEmails,setTotalEmails] = useState(0)
    const [message,setMessage] = useState("")
    const [loading,setLoading]=useState(false)

    // Retrieve Emails From Rest API 
    useEffect(()=>{
        let url = "https://razeenapp.co/api/email.php";
        fetch(url)
      .then(response => response.json())
      .then(data => {
        setEmails(data)
      })
      .catch(error => console.error(error));
    },[])
    // Send Emails
    const sendEmail = (e)=>{
        e.preventDefault()
      
        setInterval(() => {
           emails.forEach(email => {
            const templateParams = {
              to_email: email.email,
              message: message,
            };
            window.emailjs.send('service_v979rfb', 'template_j32hp7w', templateParams, 'WG4PeU7M-4y-ny9tv')
              .then(result => console.log(result.text))
              .catch(error => console.error(error));
          });
          }, 2000);
        //   emailParams.forEach((params) => {
        //     emailjs.send('service_v979rfb', 'template_j32hp7w', params, 'WG4PeU7M-4y-ny9tv')
        //       .then((result) => {
        //         console.log(`Email sent to ${params.to_email}: ${result.text}`);
        //       }, (error) => {
        //         console.log(`Error sending email to ${params.to_email}: ${error.text}`);
        //       });
        //   });
    }
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
        <MainNav />
        <div className="courses mx-6">
     <div className="flex flex-col justify-center items-center my-2">
     <h1 className='text-3xl py-4 text-primaryColor text-center'>Send Emails1</h1>
     <h1 className='text-2xl py-2 bg-secondaryColor text-black px-4 rounded-full text-center'>Total Emails: {totalEmails}</h1>
     <h1 className="text-2xl py-4 bg secondaryColor text-black text-center">Student Email:-</h1>
     </div>
      <div className="overflow-x-auto relative">
      <form onSubmit={sendEmail}>
      <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Message</label>
                                          <textarea  cols="10" rows="8" placeholder='Message' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                      </div>
                                      <button
                                            disabled={loading}
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={sendEmail}
                                            >
                                                Send
                                            </button>
</form>
  {/* {emails.map(item => (
          <li >{item.email}</li>
        ))} */}
        </div>

     
      </div>
    </div>
    </div>
    
    </div>
  )
}

export default Email