import {
  collection,
  getCountFromServer,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import MainNav from "../components/MainNav";
import Sidebar from "../components/Sidebar";
import { db } from "../utils/firebase";
import ReactLoading from "react-loading";
import { useContext } from "react";
import { AuthContext } from "../utils/AuthContext";
import { v4 } from "uuid";
import UserRoleCheck from "../components/UserRoleCheck";
function ChangePassword({ allowedRoles }) {
  const [isModal, setIsModal] = useState(false);
  const {
    parsed,
    totalInstructors,
    activeInstructors,
    noOfStudents,
    totalEnrollments,
    totalEarnings,
  } = useContext(AuthContext);

  if (isModal) {
    return <UserRoleCheck />;
  } else {
    return (
      <div className=" min-h-screen flex">
        <div className="left flex-[1_1_0%]">
          <Sidebar />
        </div>
        <div className="right flex-[3_3_0%]">
          <div className="content">
            <MainNav />
           
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
