import { collection, getDocs, limit, onSnapshot, orderBy, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { db } from '../utils/firebase';
import ReactLoading from 'react-loading';

function Test() {
    const [input,setInput] = useState([]);
    const [studentInfo,setStudentInfo] = useState([]);
    const [isEmpty,setIsEmpty]= useState(true)
    const [length,setLength] = useState(0);
    const [loading,setLoading] = useState(true)
    const [results,setResults]= useState([])
    const [courses,setCourses] = useState([])
    // useEffect(() => {

    //   const collectionRef = collection(db,'enrollment');
    //   // ,where("instructorId","!=","9e7bd9cf-fb6a-4c89-88eb-e85f8d9b3c5a")
    //   // 1Fa8RADsa5jPDOHtdo3r
    //   // Hamze habiib 9e7bd9cf-fb6a-4c89-88eb-e85f8d9b3c5a
    //   // Hamza adam 000525af-514e-47f1-8b25-d7569068528d
    //   // Khalid ismail 1db3323b-efa8-4a72-87a8-25690e825441
    //   const q = query(collectionRef,where("courseId","==", "HzVM8eJCSeA8PpN42UzE"));
    //   const updateColumn = async () => {
    //     const snapshot = await getDocs(q);
                
    //     snapshot.forEach((doc) => {
    //       const docRef = doc.ref;
    //       const data = {
    //         instructorId: "1db3323b-efa8-4a72-87a8-25690e825441"
    //       };
    //       updateDoc(docRef, data);
    //     });
    //   };
    
    //             //  updateColumn();
    //     // console.log("Done");
    //   const unsubscribe = onSnapshot(q,snapshot=>{
    //   setCourses(
    //   snapshot.docs.map(doc => ({
    //   id:doc.id,
    //   courseName:doc.data().courseName,
    //   instructor:doc.data().instructor,
    //   }))
    //   )
    //   console.log("total enrollments ",courses.length);
    //   })
    //   return () => unsubscribe();
    //   },[])
  return (
    <div className="flex flex-col items-center justify-center" >
        <h1 className=' text-3xl text-primaryColor my-12 font-semibold'>Search Data From Firestore</h1>
        <input type="text" placeholder='email@email.com' className="py-3 px-6 my-2 outline-none border-2 rounded-full border-primaryColor" value={input} onChange={e => setInput(e.target.value)} />
        <button className="bg-primaryColor py-3.5 px-6 text-white rounded-full" type='submit' >Search</button>
        {/* {courses.map((course,key) => (
            <>
            <h1 className="text-center" key={key}>{course.courseName}</h1>
            <h1 className="text-center">{course.instructor}</h1>
            </>
          ))} */}
    </div>
  )
}

export default Test