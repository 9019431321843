import React from "react";
import Sidebar from "../components/Sidebar";
import MainNav from "../components/MainNav";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import { useState } from "react";
import md5 from "md5";
import axios from "axios";

function InstructorApproval() {
  const [instructor, setInstructor] = useState([]);
  const [username, setUsername] = useState("");
  const [loading,setLoading] = useState(false)
  const [password, setPassword] = useState("123456");
  const params = useParams();
  
 

  const navigate = useNavigate()
  useEffect(() => {
    let id = params.id;
    const docRef = doc(db, "dashboardusers", id);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      setInstructor(doc.data());
    });

    return () => unsubscribe();
  }, []);
  const approveInstructor =async (e) => {
    e.preventDefault();
    setLoading(true)
    
    let lowerCaseUsername = username.toLowerCase();
    let hashedPassword = md5(password);
    const colRef = collection(db, "dashboardusers");
    const q = query(colRef,where("username","==",lowerCaseUsername))
    const querySnapshot = await getDocs(q);
   if(querySnapshot.empty === true){
     const docRef = doc(db, "dashboardusers", params.id);
    try{
      await updateDoc(docRef, {
        username: lowerCaseUsername,
        password:hashedPassword,
        activationStatus:"Approved"
      });
      let message = `Kusoo dhawoow macalimiinta platform-ka Razeen Training Center. Si aad u gasho qeybta maamul-ka macalimiinta isticmaal Link-gan hoose \n https://admin.razeenapp.co/ \n  \n Username-kaaga:${lowerCaseUsername}  \n \n Password-kaaga:${password}`;
      
      axios.get(`https://razeenapp.co/api/instructor.php?phone=${instructor?.phone}&message=${message}`,{
        headers: {
          'Cache-Control': 'no-cache',                
      }
      })
      .then(res => {
        console.log(res.data);
      })
    }
    catch(e){
      console.log(e);
    }
   }
   else{
    alert("Username already registered")
   }
    
    
   
  };
  if(instructor.activationStatus === "Approved"){
    return (
      <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content  h-full  flex items-center justify-center">
        <div className="">
        <h1 className="text-primaryColor text-4xl font-extrabold text-center ">Instructor Approved</h1>
        <br />
        <Link to="/instructors" className=" py-2 px-3 w-full bg-primaryColor text-white "><i className="fas fa-arrow-left"></i> Go To Instructors</Link>
        </div>
        </div>
        </div></div>
      
    )
  }
 else{
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <div className="courses mx-6">
            <div className="mt-2 text-center sm:ml-4 sm:text-left">
              <h4 className="text-lg font-medium text-gray-800">
                Fullname: {instructor?.instructorFullname}
              </h4>
              <h4 className="text-lg font-medium text-gray-800">
                Phone: {instructor?.phone}
              </h4>
              <h4 className="text-lg font-medium text-gray-800">
                Email: {instructor?.email}
              </h4>
              <h4 className="text-lg font-medium text-gray-800">
                Short Bio: {instructor?.instructorBio}
              </h4>
              <h4 className="text-lg font-medium text-gray-800">
                Status: {instructor?.activationStatus}
              </h4>
              <h4 className="text-lg font-medium text-red-600">
                Default Password: {password}
              </h4>
              <div className="items-center gap-2 mt-3 w">
                <form>
                  <div className="form-group flex flex-col">
                    <label className="text-primaryColor py-2">
                      Username-kan waa ka uu ku gali doono macalinku
                      dashboardkiisa
                    </label>
                    <input
                      type="text"
                      placeholder="Username"
                      value={username}
                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <br />
                    {/* <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                      onChange={(e) => setPassword(e.target.value)}
                    /> */}
                  </div>
                  <button
                    disabled={username.length < 5 || password.length < 5}
                    className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                    onClick={approveInstructor}
                  >
                    Approve Instructor
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
 }
}

export default InstructorApproval;
