import React from "react";
import { useState } from "react";
import logoBackground from '../assets/logoBackground.png'
function UserRoleCheck({ pathName, userRole }) {
  const [isOpen, setIsOpen] = useState(true);
  const instructorPagesAllowed = [
    "/",
    "/courses",
    " /languages",
    " /enrollments",
    "/withdrawals",
  ];
  const adminPagesAllowed = [
    "/",
    "/courses",
    "/languages",
    " /enrollments",
    "/withdrawals",
  ]
  const onClose = () => {
    setIsOpen(false)
  };
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-10 bg-black ">
      <div className="bg-red-600 p-6 rounded-lg shadow-lg text-center ">
      <div className="flex items-center justify-center"><img src={logoBackground} alt="" /></div>
        <div className="border border-red-600 m-4 p-4 rounded-2xl bg-red-600 text-white">
        <h2 className="text-xl font-bold mb-4">User Role Restriction</h2>
        <p className="mb-4  text-4xl ">Laguuma Ogola Qeybtan</p>
        <button
          className="bg-white hover:bg-gray-300 text-red-600 py-2 px-4 rounded"
          onClick={onClose}
        >
         
        <i className="fas fa-close mr-2"></i> Close
        </button>
        </div>
      </div>
    </div>
  );
}

export default UserRoleCheck;
