import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, where } from 'firebase/firestore'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import MainNav from '../components/MainNav'
import Sidebar from '../components/Sidebar'
import { db } from '../utils/firebase'
import firebase from 'firebase/app';
import 'firebase/firestore';
function Enrol() {
    const [courses,setCourses]=useState([])
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {

        const collectionRef = collection(db,'courses');
        const q = query(collectionRef,where("isFreeCourse" , "==","No"));
        const unsubscribe = onSnapshot(q,snapshot=>{
        setCourses(
        snapshot.docs.map(doc => ({
        id:doc.id,
        courseName:doc.data().courseName,
        isFreeCourse:doc.data().isFreeCourse,
        price:doc.data().price,
        instructor:doc.data().instructor,
        }))
        )
        })
        return () => unsubscribe();
        },[])
        // useEffect(()=>{
        //      let userId = params.id;
        //   const colRef = collection(db,'enrollment')
        //   const q = query(colRef,where("userId", "==", userId));
        //  const unsubscribe =  onSnapshot(q,(snapshot)=>{
        //   if(snapshot.docs.length !==0){
        //     setEnrolmentCourses(
        //       snapshot.docs.map(doc => ({
        //       id:doc.id,
        //       courseName:doc.data().courseName,
        //       isFreeCourse:doc.data().isFreeCourse,
        //       amount:doc.data().amount,
        //       userId:doc.data().userId,
        //       }))
        //       )
        //   }
        //   else{

        //   }
        //   })
          
        //  return () => unsubscribe();
        // },[])
        // Enrol Now
        const enrolNow = async (course) =>{
          const colRef = collection(db, "enrollment");
          const q = query(colRef,where("userId","==",params.id),where("courseId","==",course.id))
          const querySnapshot = await getDocs(q);
          console.log(querySnapshot);
         if(querySnapshot.empty === true){
              const data = {
              courseId:course.id,
              userId:params.id,
              amount:course.price,
              isCourseFree:"No",
              userRole:"",
              enrolmentDate:serverTimestamp(),
              apiType:"Manual",
              courseName:course.courseName,
              instructor:course.instructor
           };
           const colRef = collection(db, "enrollment");
                  addDoc(colRef, data)
                .then(docRef => {
                  console.log("Enrollment Successful");
                  alert("Successfully Enrolled")
                  navigate('/students')
                })
                .catch(error => {
                  console.log(error);
                })
         }
         else{
          alert("Already Enrolled This Course")
         }
         
        }
        // const deleteDocs = (id)=>{
        //   const docRef = doc(db, "enrollment",id);
        //   deleteDoc(docRef)
        //   .then(() => {
        //       console.log("Entire Document has been deleted successfully.")
        //   })
        //   .catch(error => {
        //       console.log(error);
        //   })
        //   console.log("Lesson Deleted",id);
          
          
        // }
    return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
        <MainNav />
        <div className="courses mx-6">
     <div className="flex flex-col justify-center items-center my-2">
     <h1 className='text-3xl py-4 text-primaryColor text-center'>Courses</h1>
     <h1 className="text-2xl py-4 bg secondaryColor text-black text-center">Student Email:-{params.email}</h1>
     </div>
      <div className="overflow-x-auto relative">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Course name</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Price</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Enrol Now</th>
              
            </tr>
        </thead>
        <tbody>
        {
      courses.map((course,key)=>{
        return(
          <tr key={key}>
        <td className='py-4 px-6'>{course.courseName}</td>
        <td className='py-4 px-6'>${course.price}</td>
        <td className='py-4 px-6'>
          {/* <Link to={`/enrol/${params.studentId/}`}>Enrol a course</Link> */}
          <button className="py-2 px-4 bg-primaryColor text-white rounded-md" onClick={()=>{enrolNow(course)}}>Enrol Now</button>
        </td>
      </tr>
        )
        })
      }
           
        </tbody>
    </table>
</div>

     
      </div>
    </div>
    </div>
    
    </div>
  )
}

export default Enrol