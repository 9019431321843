import { addDoc, collection, deleteDoc, doc, limit, onSnapshot, orderBy, query, serverTimestamp, where } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import MainNav from '../components/MainNav';
import Sidebar from '../components/Sidebar';
import { db } from '../utils/firebase';

function SingleCourse() {
    const params = useParams();
    const [course,setCourse] = useState([])
    const [noData,setNoData] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [courseSectionsCount,setCourseSectionsCount] = useState(0);
    const [sectionNo, setSectionNo] = useState(1);
    const [sections, setSections] = useState([]);
    // Retreive Course Data
    useEffect( () => {
        let id = params.id;
         const docRef = doc(db,'courses',id)
        const unsubscribe =  onSnapshot(docRef,(doc)=>{
            let data = doc.data();
            if(data){
                setCourse(doc.data());
                setNoData(0)
            }
            else{
                setNoData(1)
            }
            })
            
        
        return () => unsubscribe();
    },[])
    // Add New Lesson 
//     const createLesson = (e) =>{
//       e.preventDefault();
//       let id = params.id;
//       const data = {
//         "lessonNo": lessonNo,
//         "title": title,
//         "lessonType": lessonType,
//         "sectionNo":sectionNo,
//         "description":description,
//         "videoUrl":videoUrl,
//         "createdAt":serverTimestamp(),
//      };
//      const colRef = collection(db, "courses",id,"lesson");
//       addDoc(colRef, data)
//   .then(docRef => {
//       console.log("Document has been added successfully");
//       setShowModal(false)
//       setLessonNo("");
//       setTitle("");
//       setLessonType("");
//       setSectionNo("");
//       setDescription("");
//       setVideoUrl("");
//   })
//   .catch(error => {
//       console.log(error);
//   })
//     }
    // Create New Section 
    const createSection = (e) =>{
        e.preventDefault();
        let id = params.id;
        const data = {
          "sectionNo": sectionNo,
       };
       const colRef = collection(db, "courses",id,"sections");
        addDoc(colRef, data)
    .then(docRef => {
        console.log("Document has been added successfully");
        setShowModal(false)
        setSectionNo("");
    })
    .catch(error => {
        console.log(error);
    })
      }
    // Retreive Sections Data
    useEffect( () => {
            let id = params.id;
            // const docRef = doc(db,'courses',id)
            const colRef = collection(db,'courses',`${id}`,'sections')
            const q = query(colRef,orderBy("sectionNo", "asc"));
           const unsubscribe =  onSnapshot(q,(snapshot)=>{
            setCourseSectionsCount(snapshot.docs.length)
            console.log(snapshot.docs.length,"Sections")
            if(snapshot.empty){
                // setNoLessonsFound(1)
            }
            else{
                setSections(
                    snapshot.docs.map(doc => ({
                    id:doc.id,
                    sectionNo:doc.data().sectionNo,
                    }))
                    )
                    console.log(sections);
            }
          
            
            })
            
           return () => unsubscribe();
        },[])
        // Delete Section 
        const deleteSection = (id)=>{
            const docRef = doc(db, "courses", params.id,"sections",id);
            deleteDoc(docRef)
            .then(() => {
                console.log("Entire Document has been deleted successfully.")
            })
            .catch(error => {
                console.log(error);
            })
            console.log("Lesson Deleted",id,params.id);
        }
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
      <MainNav />
      <div className="courses mx-6">
     {noData === 1 ? 
     <>
     <h1 className='text-3xl py-4 text-primaryColor text-center font-bold'>No Record Found With The Provided ID</h1>
     </>
     :
     <>
     <div className="courseHeader flex justify-center flex-col my-6">
      
      <h1 className='text-3xl py-2 text-primaryColor text-center font-bold tracking-widest'>{course.courseName}</h1>
     <h5 className="text-center text-thirdColor font-semibold py-2 text-xl">{courseSectionsCount} Sections</h5>
      <button className="py-2 px-6 bg-primaryColor rounded-lg text-white mx-auto" onClick={() => setShowModal(true)}>Add New Section</button>
      </div>
     </>
     }


    {
        courseSectionsCount === 0 ? 
        <>
        <h1 className='text-3xl py-4 text-primaryColor text-center font-bold'>No Sections & Lessons Found</h1> 
        </>
        :
        <>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Section No</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>View Lessons</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Delete Section & All Lessons</th>
              
            </tr>
        </thead>
        <tbody>
          {
            sections.map((section,key)=>{
        return(
          <tr key={key}>
        <td className='py-4 px-6'>Section No: #{section.sectionNo}</td>
        {/* <td className='py-4 px-6'><button  onClick={() => deleteSection(`${section.id}`)} className='bg-red-500 p-2  px-3 rounded-md'><i className='fas fa-trash  text-white'></i></button></td> */}
        <td className='py-4 px-6'>
        <Link to={`lessons/${section.id}`} className='bg-secondaryColor py-2 px-3 rounded-md text-black'><i className="fa-solid fa-eye mr-2" ></i>View Lessons </Link>
        </td>
        <td className='py-4 px-6'><button   onClick={() => {if((window.confirm('Are you sure you wish to delete this section & lessons?'))){deleteSection(`${section.id}`)} }}  className='bg-red-500 p-2  px-3 rounded-md text-white'><i className='fas fa-trash  text-white'></i> Delete Section</button></td>
        {/* <td className='py-4 px-6'><Link to={`lesson/${params.id}/${lesson.id}`}><i className="fas fa-eye"></i> See All </Link></td> */}
      </tr>
        )
        })
      }
        
           
        </tbody>
    </table>
        </>
    }
     
      </div>
      {/* Create Course Modal */}
      <>
            {showModal ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                            Add New Lesson
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                            <form>
                                            <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Section No</label>
                                          <input type="number"  placeholder='Section No' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={sectionNo} onChange={e => setSectionNo(e.target.value)} />
                                      </div>
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={createSection}
                                            >
                                                Add New Section
                                            </button>
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                                onClick={() =>
                                                    setShowModal(false)
                                                }
                                            >
                                                Cancel
                                            </button>
                                            </form>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    </div>
    </div>
    
    </div>
  )
}

export default SingleCourse