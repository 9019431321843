import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import MainNav from "../components/MainNav";
import Sidebar from "../components/Sidebar";
import { db } from "../utils/firebase";
import { AuthContext } from "../utils/AuthContext";

function Enrollments() {
  // const [enrollments,setEnrollments] = useState([]);
  // const [totalEarnings,setTotalEarnings] = useState(0);
  // const [showStudentInfo,setShowStudentInfo] = useState(false);
  // const [studentInfo,setStudentInfo] = useState([]);
  // const [totalEnrollments,setTotalEnrollments] = useState(0)
  const [courses,setCourses]=useState([])
  const [enrollments, setEnrollments] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalEnrollments, setTotalEnrollments] = useState("");
  const {parsed} = useContext(AuthContext);
  // Get Total Earnings
  useEffect(() => {
    let userRole = parsed?.userRole;
   if(userRole === "Instructor"){
    const getTotalEarnings = async () => {
      const q = query(
        collection(db, "enrollment"),
        where("isCourseFree", "==", "No"),
        where("instructorId", "==", parsed?.instructorId),
        where("userRole", "!=", "Testing")
      );
      let total = 0;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let convertedString = {
          amount: Number(doc.data().amount),
        };

        total += convertedString.amount;

      });

      setTotalEarnings(total);
    };
    getTotalEarnings();
   }else{
    const getTotalEarnings = async () => {
      const q = query(
        collection(db, "enrollment"),
        where("isCourseFree", "==", "No"),
        where("userRole", "!=", "Testing")
      );
      let total = 0;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let convertedString = {
          amount: Number(doc.data().amount),
        };

        total += convertedString.amount;

      });

      setTotalEarnings(total);
    };
    getTotalEarnings();
   }
    
  }, []);
  useEffect(() => {
   let userRole = parsed?.userRole;
   console.log(parsed?.instructorId)
   if(userRole === "Instructor"){
    const getCollectionSize = async () => {
      const colRef = collection(db, "enrollment");
      const q = query(
        colRef,
        where("isCourseFree", "==", "No"),
        where("userRole", "!=", "Testing"),
        where("instructorId", "==", parsed.instructorId),
      );
      const querySnapshot = await getDocs(q);
      setTotalEnrollments(querySnapshot.size);
    };

    getCollectionSize();
   }
   else{
    const getCollectionSize = async () => {
      const colRef = collection(db, "enrollment");
      const q = query(
        colRef,
        where("isCourseFree", "==", "No"),
        where("userRole", "!=", "Testing"),
        
      );
      const querySnapshot = await getDocs(q);
      setTotalEnrollments(querySnapshot.size);
    };

    getCollectionSize();
   }
  }, []);
  useEffect(() => {
      let userRole = parsed?.userRole;
      if(userRole === "Instructor"){
        const collectionRef = collection(db,'courses');
      const q = query(collectionRef,where("isFreeCourse" , "==","No"),where("instructorId","==",parsed?.instructorId));
      const unsubscribe = onSnapshot(q,snapshot=>{
      setCourses(
      snapshot.docs.map(doc => ({
      id:doc.id,
      courseName:doc.data().courseName,
      isFreeCourse:doc.data().isFreeCourse,
      price:doc.data().price,
      instructor:doc.data().instructor,
      }))
      )
      })
      return () => unsubscribe();
      }
      else{
        const collectionRef = collection(db,'courses');
      const q = query(collectionRef,where("isFreeCourse" , "==","No"));
      const unsubscribe = onSnapshot(q,snapshot=>{
      setCourses(
      snapshot.docs.map(doc => ({
      id:doc.id,
      courseName:doc.data().courseName,
      isFreeCourse:doc.data().isFreeCourse,
      price:doc.data().price,
      instructor:doc.data().instructor,
      }))
      )
      })
      return () => unsubscribe();
      }
      },[])
  // Get Enrollments
  // useEffect(() => {

  //     const collectionRef = collection(db,'enrollment');
  //     const q = query(collectionRef,where("isCourseFree" ,"==","No"),where("userRole","!=",null));
  //     const unsubscribe = onSnapshot(q,snapshot=>{
  //     setTotalEnrollments(snapshot.docs.length)
  //     setEnrollments(
  //     snapshot.docs.map(doc => ({
  //     id:doc.id,
  //     courseName:doc.data().courseName,
  //     courseId:doc.data().courseId,
  //     instructor:doc.data().instructor,
  //     isFreeCourse:doc.data().isFreeCourse,
  //     userId:doc.data().userId,
  //     apiType:doc.data().apiType,
  //     amount:doc.data().amount,
  //     }))
  //     )
  //     })
  //     return () => unsubscribe();
  //     },[])
  // Get Student Data
  // const getStudentData = async(enrollment)=>{
  //   // console.log(enrollment);
  //   const q = query(collection(db, "students"), where("userId", "==", enrollment.userId));

  // const querySnapshot = await getDocs(q);
  // console.log(querySnapshot.size)
  // querySnapshot.forEach((doc) => {
  //   // doc.data() is never undefined for query doc snapshots
  //   console.log(doc.id, " => ", doc.data());
  //   setStudentInfo(doc.data())
  // });
  // }
  return (
    <div className=" min-h-screen flex">
      <div className="left flex-[1_1_0%]">
        <Sidebar />
      </div>
      <div className="right flex-[3_3_0%]">
        <div className="content">
          <MainNav />
          <div className="courses mx-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-y-4 my-8">
          <div className="box bg-[#7da0fa] h-40 w-54 rounded-xl shadow-xl">
          <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                Total Enrollments
              </h1>
              <h1 className="text-center text-6xl font-semibold text-white">
              {totalEnrollments}
              </h1>
          </div>
          <div className="box bg-[#4747a1] h-40 w-54 rounded-xl shadow-xl ml-4">
          <h1 className="text-3xl py-2 text-white text-center font-bold tracking-widest">
                Total Earnings
              </h1>
              <h1 className="text-center text-6xl font-semibold text-white">
              ${totalEarnings}
              </h1>
          </div>
          </div>
            <div className="courseHeader flex justify-center flex-col my-6">
              <h1 className="text-3xl py-2 text-primaryColor text-center font-bold tracking-widest">
                Courses List 
              </h1>
            </div>
            <div className="overflow-scroll relative my-4">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Course name</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Price</th>
             
            </tr>
        </thead>
        <tbody>
        {
      courses.map((course,key)=>{
        return(
          <tr key={key}>
        <td className='py-4 px-6'>{course.courseName}</td>
        <td className='py-4 px-6'>${course.price}</td>
        <td className='py-4 px-6'>
          {/* <Link to={`/enrol/${params.studentId/}`}>Enrol a course</Link> */}
          <Link to={`/enrollmentdata/${course.id}/${course.courseName}/${course.price}`} className="py-2 px-4 bg-primaryColor text-white rounded-md" >Show Enrollments</Link>
        </td>
      </tr>
        )
        })
      }
           
        </tbody>
    </table>
            </div>

            {/* <>
            {showStudentInfo ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowStudentInfo(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                           Student Information
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                          <h1 className='text-primaryColor font-semibold my-2 '>Fullname:- {studentInfo.fullname}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>Email:- {studentInfo.email}</h1>
                                          <h1 className='text-primaryColor font-semibold my-2 '>Whatsapp:- {studentInfo.whatsapp}</h1>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Enrollments;
