import React from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainNav from '../components/MainNav'
import Sidebar from '../components/Sidebar'
import { db, storage } from '../utils/firebase';
import {v4} from 'uuid'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { collection, doc, getDoc, query, updateDoc, where } from 'firebase/firestore';
import { useEffect } from 'react';

function Upload() {
    const params = useParams()
    const navigate = useNavigate()
    const [courseFile,setCourseFile] = useState("");
    const [loading,setLoading] = useState(false)
    const [isCourseFile,setIsCourseFile] = useState(0)
    const [url,setUrl] = useState("");
    const [fileUrl,setFileUrl] = useState("");
    useEffect(()=>{
        const checkCourseFile = async()=>{
            const docRef = doc(db, "courses",params.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
         if(docSnap.data().courseFile !== ""){
            setIsCourseFile(1)
            setFileUrl(docSnap.data().courseFile)
         }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
        }
        return ()=>{checkCourseFile()}
    },[])
    const uploadFile = async (e)=>{
        e.preventDefault()
       
        
        setLoading(true)
    e.preventDefault();
    const courseFileRef = ref(storage,`books/${courseFile.name + v4()}`);
    uploadBytes(courseFileRef,courseFile)
    .then((snapshot)=>{
        const uploadTask = uploadBytesResumable(courseFileRef, courseFile);
        uploadTask.on("state_changed",
      (snapshot) => {
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        const data = {
                            "courseFile": downloadURL,
                        };
                        const docRef = doc(db, "courses", params.id);
                          updateDoc(docRef, data)
                          .then(docRef => {
                              console.log("A New Document Field has been added to an existing document");
                              alert("Successfully Uploaded!")
                              navigate('/courses')
                          })
                          .catch(error => {
                              console.log(error);
                          })
                        });
      }
    );
    })
    }
    const removeFile = ()=>{
        const data = {
            "courseFile": "",
        };
        const docRef = doc(db, "courses", params.id);
          updateDoc(docRef, data)
          .then(docRef => {
              console.log("A New Document Field has been added to an existing document");
              alert("Successfully Removed!")
              navigate('/courses')
          })
          .catch(error => {
              console.log(error);
          })
    }
  return (
    <>
         <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
      <MainNav />
      <div className="courses mx-6">
  

      <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                            {params.courseName}
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                            {
                                                isCourseFile === 1 ?
                                                <>
                                                <div className="flex flex-col justify-center items-center">
                                                <a href={fileUrl} target="_blank" className='bg-green-500 text-white py-2 px-6 w-full rounded-md outline-none text-center'><i className="fa fa-download"></i> Download Course File</a>
                                                <button
                                                className="w-full mt-2 p-2.5  text-white bg-red-500 rounded-md outline-none"
                                                onClick={removeFile}
                                            >
                                                <i className="fa fa-trash"></i> Remove Course File
                                            </button>
                                                </div>
                                                </>
                                                :
                                                <>
                                                <form>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Upload Course File</label>
                                          <input type="file" placeholder='Course File' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' onChange={e => setCourseFile(e.target.files[0])}  />
                                      </div>
                                            <button
                                            disabled={loading}
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={uploadFile}
                                            >
                                                Upload Course File
                                            </button>
                                            </form>
                                                </>
                                            }
                                           
                                        </div>
                                    </div>
 
     
      </div>
      
    </div>
    </div>
    
    </div>
    </>
  )
}

export default Upload