import React, { useEffect, useState } from 'react'
import AddCourse from '../components/AddCourse'
import MainNav from '../components/MainNav'
import DataTable from 'react-data-table-component';
import Sidebar from '../components/Sidebar'
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp, where } from 'firebase/firestore';
import { db,storage } from '../utils/firebase';
import { Link } from 'react-router-dom';
import{getDownloadURL, ref, uploadBytes, uploadBytesResumable} from 'firebase/storage'
import ReactLoading from 'react-loading';
import {v4} from 'uuid'
function Books() {
    const [books,setBooks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [bookName, setBookName] = useState("");
    const [bookDesc, setBookDesc] = useState("");
    const [author, setAuthor] = useState("");
    const[thumbnailFile,setThumbnailFile] = useState("");
    const [file, setFile] = useState("");
    const [url, setUrl] = useState("");
    const [thumbnailURL, setThumbnailURL] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [bookThumbnail, setBookThumbnail] = useState("");
    const [showModal2,setShowModal2] = useState(false)
    const [audioBookId,setAudioBookId] = useState("");
    const [audioUrl,setAudioUrl] = useState("")
    const [loading,setLoading] = useState(false);
    const [searchVal,setSearchVal] = useState("");
     // Addd new book 
  const addBook = (e) =>{
    setLoading(true)
    e.preventDefault();
    // Upload File 
    const fileRef = ref(storage,`books/${file.name + v4()}`);
    const thumbFileRef = ref(storage,`books/${thumbnailFile.name + v4()}`);
    uploadBytes(fileRef,file)
    .then((snapshot)=>{
        const uploadTask = uploadBytesResumable(fileRef, file);
        uploadTask.on("state_changed",
      (snapshot) => {
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //  setUrl(downloadURL)
            let fileURI = downloadURL;
            uploadBytes(thumbFileRef,thumbnailFile)
            .then((snapshot)=>{
                const uploadTask = uploadBytesResumable(thumbFileRef, thumbnailFile);
                uploadTask.on("state_changed",
              (snapshot) => {
              },
              (error) => {
                alert(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    let thumbnailFileURI = downloadURL;
                
                    const data = {
                        "bookName": bookName,
                        "bookDesc": bookDesc,
                        "bookThumbnail": thumbnailFileURI,
                        "url":fileURI,
                        "author":author,
                        "uploadedAt":serverTimestamp(),
                    };
                    const colRef = collection(db, "books");
                        addDoc(colRef, data)
                    .then(docRef => {
                        console.log("Document has been added successfully");
                        alert("Successfully Uploaded")
                        setShowModal(false)
                        setBookName("");
                        setBookDesc("");
                        setThumbnailFile("");
                        setFile("");
                        setAuthor("");
                        setLoading(false)
                    })
                    .catch(error => {
                        console.log(error);
                    })
                });
              }
            );
            })
             
        });
      }
    );
    })
   

    

  }
     // Delete Book
  const deleteCourse =(id)=>{
    const docRef = doc(db, "books", id);
    deleteDoc(docRef)
    .then(() => {
        console.log("Entire Document has been deleted successfully.")
    })
    .catch(error => {
        console.log(error);
    })
  }
      useEffect(() => {

        const collectionRef = collection(db,'books');
        const q = query(collectionRef,orderBy("uploadedAt","asc"));
        const unsubscribe = onSnapshot(q,snapshot=>{
        setBooks(
        snapshot.docs.map(doc => ({
        id:doc.id,
        bookName:doc.data().bookName,
        author:doc.data().author,
        bookDesc:doc.data().bookDesc,
        bookThumbnail:doc.data().bookThumbnail,
        uploadedAt:doc.data().uploadedAt,
        url:doc.data().url
        }))
        )
        })
        return () => unsubscribe();
        },[])
         // Filter Search
    const filteredData = books.filter(books =>
      books.bookName.toLowerCase().includes(searchVal.toLowerCase())
    );

    const addAudio =(id)=>{
      setLoading(true)
      const data = {
        "url": audioUrl,
        "createdAt":serverTimestamp(),
    };
    const colRef = collection(db, "books",id.id,"audio");
        addDoc(colRef, data)
    .then(docRef => {
        console.log("Document has been added successfully");
        alert("Successfully Uploaded")
        setShowModal2(false)
        setAudioUrl("");
        setLoading(false)
    })
    .catch(error => {
        console.log(error);
    })
    }
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
      <MainNav />
        <>
        <div className="courses mx-6">
      <div className="courseHeader flex justify-center flex-col my-6">
      
      <h1 className='text-3xl py-2 text-primaryColor text-center font-bold tracking-widest'>Books List</h1>
      <button className="py-2 px-6 bg-primaryColor rounded-lg text-white mx-auto " onClick={() => setShowModal(true)}>Add New Book</button>
      </div>
      <div class="overflow-scroll relative">
      <div className=' py-2  focus:outline-0 my-2 rounded-full border-primaryColor/25 px-4 border-2'>
    <input type="text" name='username' placeholder='Search Book By Book name.......' className='w-[97%] outline-none' value={searchVal} onChange={e => setSearchVal(e.target.value)} />
    <i className="fas fa-search"></i>
    </div>
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Book name</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Short Desc</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Author</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Thumbnail</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Download URL</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Add Audio</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Update</th>
            <th scope="col" className='py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white'>Delete</th>
              
            </tr>
        </thead>
        <tbody>
        {
      filteredData.map((book,key)=>{
        return(
          <tr key={key}>
        <td className='py-4 px-6'>{book.bookName}</td>
        <td className='py-4 px-6'>{book.bookDesc = book.bookDesc.substring(0,50)}</td>
        <td className='py-4 px-6'>{book.author}</td>
        <td className='py-4 px-6'><img src={book.bookThumbnail} alt="" width={60} height={60} /></td>
        <td className='py-4 px-6'><a href={book.url} target="_blank">Click To See</a></td>
        <td className='py-4 px-6'>
        <button onClick={() => {
          setShowModal2(true)
          setAudioBookId(book)
          }} className='bg-green-600 py-2 px-3 rounded-md'><i className="fas fa-plus text-white" ></i></button>
        </td>
        <td className='py-4 px-6'>
        <Link to={`update/${book.id}`} className='bg-secondaryColor py-2 px-3 rounded-md'><i className="fas fa-pen text-black" ></i></Link>
        </td>
        <td className='py-4 px-6'><button   onClick={() =>{if ((window.confirm('Are you sure you wish to delete this book?'))){ deleteCourse(`${book.id}`)} }} className='bg-red-500 p-2  px-3 rounded-md'><i className='fas fa-trash  text-white'></i></button></td>
      </tr>
        )
        })
      }
           
        </tbody>
    </table>
</div>
{/* Create Course Modal */}
       <>
            {showModal ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowModal(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                            Add New Book
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                            <form>
                                            <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Book name</label>
                                          <input type="text" placeholder='Book name' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={bookName} onChange={e => setBookName(e.target.value)}  required/>
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Author</label>
                                          <input type="text"  placeholder='Author' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={author} onChange={e => setAuthor(e.target.value)} required />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Upload Book Thumbnail Image</label>
                                          <input type="file" placeholder='Book Thumbnail' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' onChange={e => setThumbnailFile(e.target.files[0])}  required />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>File</label>
                                          <input type="file" className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' onChange={e => setFile(e.target.files[0])}  required/>
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Description</label>
                                          <textarea  cols="10" rows="3" placeholder='Book Description' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={bookDesc} onChange={e => setBookDesc(e.target.value)}></textarea>
                                      </div>
                                           
                                           
                                            {
                                              loading ? 
                                              <>
                                              <div className="w-full flex justify-center items-center">
                                            <ReactLoading type="cylon" color="#007474" height={'20%'} width={'20%'} />
                                            </div>
                                              </>
                                              :
                                              <>
                                              <button
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={addBook}
                                                disabled={!file || !author || !bookName || !bookDesc || !thumbnailFile}
                                            >
                                                Add New Book
                                            </button>
                                              </>
                                            }
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                                onClick={() =>
                                                    setShowModal(false)
                                                }
                                            >
                                                Cancel
                                            </button>
                                            </form>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
                                              

        <>
            {showModal2 ? (
                <>
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div
                            className="fixed inset-0 w-full h-full bg-black opacity-50"
                            onClick={() => setShowModal2(false)}
                        ></div>
                        <div className="flex items-center min-h-screen px-4 py-8">
                            <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                                <div className="mt-3">
                                    
                                    <div className="mt-2 text-center sm:ml-4 sm:text-left">
                                        <h4 className="text-lg font-medium text-gray-800">
                                            Add An Audio To This Book {audioBookId.bookName}
                                        </h4>
                                        <div className="items-center gap-2 mt-3 w">
                                            <form>
                                            <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>URL</label>
                                          <input type="text" placeholder='Audio URL' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={audioUrl} onChange={e => setAudioUrl(e.target.value)}  required/>
                                      </div>
                                           
                                           
                                            {
                                              loading ? 
                                              <>
                                              <div className="w-full flex justify-center items-center">
                                            <ReactLoading type="cylon" color="#007474" height={'20%'} width={'20%'} />
                                            </div>
                                              </>
                                              :
                                              <>
                                              <button
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={(e)=>{
                                                  e.preventDefault()
                                                  addAudio(audioBookId)
                                                  }}
                                                disabled={!audioUrl}
                                            >
                                                Add Audio
                                            </button>
                                              </>
                                            }
                                            <button
                                                className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                                onClick={() =>
                                                    setShowModal2(false)
                                                }
                                            >
                                                Cancel
                                            </button>
                                            </form>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
 

        
      </div>
        </>
    </div>
    </div>
    
    </div>
  )
}

export default Books