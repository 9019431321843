import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import MainNav from "../components/MainNav";
import Sidebar from "../components/Sidebar";
import { db, storage } from "../utils/firebase";
import { v4 } from "uuid";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
function Lessons() {
  const params = useParams();
  const location = useLocation();
  let str = location.pathname;
  let start = str.indexOf("/courses/course/");
  let end = str.indexOf("/lessons/");
  let substring = str.substring(start, end);
  let remove = "/courses/course/";
  let courseId = substring.replace(remove, "");
  const [course, setCourse] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [noData, setNoData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [noLessonsFound, setNoLessonsFound] = useState(1);
  const [loading, setLoading] = useState(false);
  const [magacaCourse,setMagacaCourse]= useState("");
  const [courseLessonsCount, setCourseLessonsCount] = useState(0);
  const [description, setDescription] = useState("");
  const [lessonNo, setLessonNo] = useState(1);
  const [selectedSource, setSelectedSource] = useState("Firebase Storage");
  const [lessonType, setLessonType] = useState("video");
  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [fsVideo, setFsVideo] = useState(undefined);

  // Retreive Course Info
  useEffect(() => {
    const getCourseInfo = async () => {
      const docRef = doc(db, "courses",courseId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setMagacaCourse(docSnap.data().courseName);
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    return () => getCourseInfo ()
  }, []);
  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
  };
  //  Add New Lesson
  const createLesson = (e) => {
    setLoading(true);
    e.preventDefault();
    
    if (selectedSource === "Firebase Storage") {
      const fsVideoRef = ref(
        storage,
        `courses/${magacaCourse}/lessons/${fsVideo.name + v4()}`
      );
      uploadBytes(fsVideoRef, fsVideo).then((snapshot) => {
        const uploadTask = uploadBytesResumable(fsVideoRef, fsVideo);
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            alert(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              const data = {
                lessonNo: lessonNo,
                title: title,
                lessonType: lessonType,
                description: description,
                videoUrl: downloadURL,
                source: selectedSource,
                createdAt: serverTimestamp(),
              };
              const colRef = collection(
                db,
                "courses",
                courseId,
                "sections",
                params.id,
                "lessons"
              );
              addDoc(colRef, data)
                .then((docRef) => {
                  setLoading(false);
                  setShowModal(false);
                  setLessonNo(lessonNo + 1);
                  setTitle("");
                  setLessonType("");
                  setDescription("");
                  setVideoUrl("");
                })
                .catch((error) => {
                  console.log(error);
                });
            });
          }
        );
      });
    } else {
      const data = {
        lessonNo: lessonNo,
        title: title,
        lessonType: lessonType,
        description: description,
        videoUrl: videoUrl,
        createdAt: serverTimestamp(),
      };
      const colRef = collection(
        db,
        "courses",
        courseId,
        "sections",
        params.id,
        "lessons"
      );
      addDoc(colRef, data)
        .then((docRef) => {
          setLoading(false);
          setShowModal(false);
          setLessonNo(lessonNo + 1);
          setTitle("");
          setLessonType("");
          setDescription("");
          setVideoUrl("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // Retreive Lessons Data
  useEffect(() => {
    const colRef = collection(
      db,
      "courses",
      `${courseId}`,
      "sections",
      params.id,
      "lessons"
    );
    const q = query(colRef, orderBy("lessonNo", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setCourseLessonsCount(snapshot.docs.length);
      console.log(snapshot.docs.length, "Lessons");
      if (snapshot.empty) {
        setNoLessonsFound(1);
      } else {
        setLessons(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            lessonNo: doc.data().lessonNo,
            description: doc.data().description,
            lessonType: doc.data().lessonType,
            source: doc.data().source,
            videoUrl: doc.data().videoUrl,
          }))
        );
        console.log(lessons);
        setNoLessonsFound(0);
      }
    });

    return () => unsubscribe();
  }, [courseId]);
  // Delete Lesson From The Course
  const deleteLesson = (id) => {
    const docRef = doc(
      db,
      "courses",
      courseId,
      "sections",
      params.id,
      "lessons",
      id
    );
    deleteDoc(docRef)
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("Lesson Deleted", id);
  };
  return (
    <>
      <div className=" min-h-screen flex">
        <div className="left flex-[1_1_0%]">
          <Sidebar />
        </div>
        <div className="right flex-[3_3_0%]">
          <div className="content">
            <MainNav />
            <div className="courses mx-6">
              {noData === 1 ? (
                <>
                  <h1 className="text-3xl py-4 text-primaryColor text-center font-bold">
                    No Record Found With The Provided ID
                  </h1>
                </>
              ) : (
                <>
                  <div className="courseHeader flex justify-center flex-col my-6">
                    <h1 className="text-3xl py-2 text-primaryColor text-center font-bold tracking-widest">
                      {magacaCourse}
                    </h1>
                    <h5 className="text-center text-thirdColor font-semibold py-2 text-xl">
                      {courseLessonsCount} Lesson(s)
                    </h5>
                    <button
                      className="py-2 px-6 bg-primaryColor rounded-lg text-white mx-auto"
                      onClick={() => setShowModal(true)}
                    >
                      Add New Lesson
                    </button>
                  </div>
                </>
              )}

              {courseLessonsCount === 0 ? (
                <>
                  <h1 className="text-3xl py-4 text-primaryColor text-center font-bold">
                    No Lessons Found
                  </h1>
                </>
              ) : (
                <>
                  <table
                    className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                    id="table_id"
                  >
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th
                          scope="col"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Lesson No
                        </th>
                        <th
                          scope="col"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Source
                        </th>
                        <th
                          scope="col"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Description {course.courseName}
                        </th>
                        <th
                          scope="col"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          Delete
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lessons.map((lesson, key) => {
                        return (
                          <tr key={key}>
                            <td className="py-4 px-6">{lesson.lessonNo}</td>
                            <td className="py-4 px-6">{lesson.title}</td>
                            <td className="py-4 px-6">
                              {lesson.source !== undefined
                                ? lesson.source
                                : "Vimeo"}
                            </td>
                            <td className="py-4 px-6">{lesson.description}</td>
                            <td className="py-4 px-6">
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you wish to delete this lesson?"
                                    )
                                  ) {
                                    deleteLesson(`${lesson.id}`);
                                  }
                                }}
                                className="bg-red-500 p-2  px-3 rounded-md"
                              >
                                <i className="fas fa-trash  text-white"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            {/* Create Lesson Modal */}
            <>
              {showModal ? (
                <>
                  <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div
                      className="fixed inset-0 w-full h-full bg-black opacity-50"
                      onClick={() => setShowModal(false)}
                    ></div>
                    <div className="flex items-center min-h-screen px-4 py-8">
                      <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3">
                          <div className="mt-2 text-center sm:ml-4 sm:text-left">
                            <h4 className="text-lg font-medium text-gray-800">
                              Add New Lesson
                            </h4>
                            <div className="items-center gap-2 mt-3 w">
                              <form>
                                <div className="form-group flex flex-col">
                                  <label className="text-primaryColor py-2">
                                    Lesson No
                                  </label>
                                  <input
                                    type="number"
                                    placeholder="Lesson No"
                                    className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                    value={lessonNo}
                                    onChange={(e) =>
                                      setLessonNo(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group flex flex-col">
                                  <label className="text-primaryColor py-2">
                                    Lesson Title
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Lesson Title"
                                    className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                  />
                                </div>

                                {/* <div className="form-group flex flex-col">
                                  <label className="text-primaryColor py-2">
                                    Lesson Type
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Type video or file"
                                    className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                    value={lessonType}
                                    onChange={(e) =>
                                      setLessonType(e.target.value)
                                    }
                                  />
                                </div> */}
                                <div className="form-group flex flex-col">
                                  <label className="text-primaryColor py-2">
                                    Source (Vimeo or Firebase Storage) *
                                  </label>
                                  <div className="flex items-center space-x-4">
                                    {/* Cloud Storage */}
                                    <div>
                                      <input
                                        id="Firebase Storage"
                                        name="source"
                                        type="radio"
                                        className="form-radio text-indigo-600 h-4 w-4"
                                        value="Firebase Storage"
                                        checked={
                                          selectedSource === "Firebase Storage"
                                        }
                                        onChange={handleSourceChange}
                                      />
                                      <label
                                        className="ml-2 text-gray-700"
                                        htmlFor="Firebase Storage"
                                      >
                                        Firebase Storage {videoUrl}
                                      </label>
                                    </div>
                                    {/* Vimeo */}
                                    <div>
                                      <input
                                        id="Vimeo"
                                        name="source"
                                        type="radio"
                                        className="form-radio text-indigo-600 h-4 w-4"
                                        value="Vimeo"
                                        checked={selectedSource === "Vimeo"}
                                        onChange={handleSourceChange}
                                      />
                                      <label
                                        className="ml-2 text-gray-700"
                                        htmlFor="Vimeo"
                                      >
                                        Vimeo
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {selectedSource === "Vimeo" && (
                                  <div className="form-group flex flex-col">
                                    <label className="text-primaryColor py-2">
                                      Vimeo URL
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Vimeo URL"
                                      className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      value={videoUrl}
                                      onChange={(e) =>
                                        setVideoUrl(e.target.value)
                                      }
                                    />
                                  </div>
                                )}
                                {selectedSource === "Firebase Storage" && (
                                  <div className="form-group flex flex-col">
                                    <label
                                      className="bg-primaryColor text-white text-center cursor-pointer border-2 my-2 rounded-xl border-primaryColor/25 py-2"
                                      htmlFor="Firebase Video"
                                    >
                                      {fsVideo === undefined ? (
                                        <>
                                          Upload Lesson Video{" "}
                                          <i class="fa-solid fa-cloud-arrow-up"></i>
                                        </>
                                      ) : (
                                        <>{fsVideo.name}</>
                                      )}
                                    </label>
                                    <input
                                      id="Firebase Video"
                                      type="file"
                                      accept="video/*"
                                      placeholder="Upload Video Directly"
                                      className="py-2 hidden  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                      onChange={(e) =>
                                        setFsVideo(e.target.files[0])
                                      }
                                    />
                                  </div>
                                )}
                                {/* <div className="form-group flex flex-col">
                                  <label className="text-primaryColor py-2">
                                    VIMEO URL
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="VIMEO URL"
                                    className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                    value={videoUrl}
                                    onChange={(e) =>
                                      setVideoUrl(e.target.value)
                                    }
                                  />
                                </div> */}
                                <div className="form-group flex flex-col">
                                  <label className="text-primaryColor py-2">
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="Description"
                                    className="py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                  />
                                </div>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                  onClick={createLesson}
                                >
                                  Add New Lesson
                                </button>
                                <button
                                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                  onClick={() => setShowModal(false)}
                                >
                                  Cancel
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </>
          </div>
        </div>
      </div>
    </>
  );
}
export default Lessons;
