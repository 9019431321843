import React from 'react'
import { Link } from 'react-router-dom'

function InstructorSidebar() {
  
  return (
    <ul className='flex flex-col py-10'>
          <li className='text-white py-4 px-6 border-2 border-[#007474] hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/" className='tracking-wider'><i className="fas fa-home mr-4 max-[650px]:text-2xl "></i> <span className='max-[650px]:hidden'>Home</span> </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-[#007474] hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/courses" className='tracking-wider'><i className="fas fa-book mr-4  max-[650px]:text-2xl"></i><span className='max-[650px]:hidden'>My Courses</span>   </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-[#007474] hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/enrollments" className='tracking-wider'><i className="fas fa-book mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>My Enrollments</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-[#007474] hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/enrollments" className='tracking-wider'><i className="fas fa-dollar-sign mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>My Earnings</span>  </Link>
          </li>
          {/* <li className='text-white py-4 px-6 border-2 border-[#007474] hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/enrollments" className='tracking-wider'><i className="fas fa-dollar-sign mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Withdrawals</span>  </Link>
          </li> */}
          
        </ul>
  )
}

export default InstructorSidebar