import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext";

function MainNav() {
  const [show, setShow] = useState(false);
  const { setIsLoggedIn, signout, dashUsers } = useContext(AuthContext);
  let parsed = JSON.parse(dashUsers);
  const showMore = () => {
    setShow(!show);
    console.log(show);
  };

  return (
    <div className="mx-12">
      <nav className="flex justify-between pt-4 items-center ">
        <div className="search">
          <input
            type="text"
            placeholder="Search for courses....."
            className="focus:outline-0 border-2 border-primaryColor/30 px-4 rounded-full text-primaryColor"
          />
          <button className="bg-primaryColor text-white px-2 text-center mx-2 rounded-full">
            <i className="fas fa-search"></i>
          </button>
        </div>
        <div className="logo flex items-center">
          <div className="notifications flex relative mr-2">
            <Link to="/notifications" className="text-primaryColor">
              <i className="fas fa-bell p-2 text-2xl"></i>
            </Link>
            <h1 className="absolute  text-white bg-red-600 rounded-full h-4 w-4 text-center text-xs top-1 right-1">
              3
            </h1>
          </div>
          <div className="profile mr-2 flex-col flex">
            <div className="account ">
              <div className="link">
                {/* <button className='text-primaryColor'  onClick={showMore}><i className="fas fa-user  p-2 "></i> UserInfo </button> */}
              </div>
              {/* className={!show ? 'hidden' : ''} */}
            </div>
            <div className="relative">
              {/* <button
        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline"
        onClick={showMore}
      >
        Dropdown
      </button> */}
              <button
                className="text-primaryColor capitalize flex items-center"
                onClick={showMore}
              >
                <div className="h-8 w-8 rounded-full mr-2 flex items-center justify-center">
                  <img
                    src={parsed?.instructorImage}
                    alt=""
                    className="w-6 h-6 object-cover rounded-full "
                  />
                </div>
                {parsed?.username}{" "}
              </button>
              {show && (
                <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg">
                  <div className="py-1">
                    <Link
                      to="/profile"
                      className="flex px-4 py-2 text-gray-700 hover:bg-gray-100 items-center w-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                        />
                      </svg>
                      Profile
                    </Link>
                    {/* <Link
                      to="/changepassword"
                      className="flex px-4 py-2 text-gray-700 hover:bg-gray-100 items-center w-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z"
                        />
                      </svg>
                      Change Password
                    </Link> */}
                    <button
                      onClick={signout}
                      className=" px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center w-full "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                        />
                      </svg>
                      Signout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default MainNav;
