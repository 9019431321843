import React from 'react'
import { Link } from "react-router-dom";
function AdminSidebar() {
  return (
    <ul className='flex flex-col py-10'>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/" className='tracking-wider'><i className="fas fa-home mr-4 max-[650px]:text-2xl "></i> <span className='max-[650px]:hidden'>Home</span> </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/courses" className='tracking-wider'><i className="fas fa-book mr-4  max-[650px]:text-2xl"></i><span className='max-[650px]:hidden'>Courses</span>   </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/students" className='tracking-wider'><i className="fas fa-users mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Students</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/books" className='tracking-wider'><i className="fas fa-book mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Books</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/subscriptions" className='tracking-wider'><i className="fas fa-envelope mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Subscriptions</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/enrollments" className='tracking-wider'><i className="fas fa-book mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Enrollments</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/enrollmentsreport" className='tracking-wider'><i className="fas fa-list mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Enrollments Report</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/yearlyreport" className='tracking-wider'><i className="fas fa-list mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Yearly Report</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          <Link to="/instructors" className='tracking-wider'><i className="fas fa-chalkboard-user mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Instructors</span>  </Link>
          </li>
          <li className='text-white py-4 px-6 border-2 border-primaryColor hover:border-2 hover:text-opacity-60  hover:text-white hover:cursor-pointer  text-sm hover:transition hover:ease-in-out hover:duration-250'>
          {/* <Link to="/https://razeenapp.co/api/whatsapp.php" target="_blank" className='tracking-wider'></Link> */}
          <a href="https://razeenapp.co/api/whatsapp.php" target="_blank" rel="noopener noreferrer" className='tracking-wider'><i className="fa-brands fa-whatsapp mr-4  max-[650px]:text-2xl"></i> <span className='max-[650px]:hidden'>Send Whatsapp</span>  </a>
          </li>
          
        </ul>
  )
}

export default AdminSidebar