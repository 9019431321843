import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import MainNav from '../components/MainNav';
import Sidebar from '../components/Sidebar';
import { db, storage } from '../utils/firebase';
import ReactLoading from 'react-loading';
import {v4} from 'uuid'
import{getDownloadURL, ref, uploadBytes, uploadBytesResumable} from 'firebase/storage'
import { useRef } from 'react';

function UpdateBook() {
    const params = useParams();
    const [book,setBook] = useState([])
    const [noData,setNoData] = useState("");
    const [bookName, setBookName] = useState("");
  const [author, setAuthor] = useState("");
  const [bookDesc, setBookDesc] = useState("");
  const [bookThumbnail, setBookThumbnail] = useState(null);
  const [file, setFile] = useState(null);
    const [noLessonsFound,setNoLessonsFound] = useState(1);
    const [loading,setLoading] = useState(false)
    const fileInputRef = useRef(null);
    const thumbnailInputRef = useRef(null);
    // Update Book Data 
    const updateBook =(e)=>{
        setLoading(true)
        e.preventDefault();
        const docRef = doc(db, "books", params.id);
        // Update Book Name Field
        if(bookName.length !== 0){
            let data = {
                "bookName": bookName,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setBookName("");
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Author Field
        if(author.length !==0){
            let data = {
                "author": author,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setAuthor("");
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })
        }
         // Update Book Desc Field
         if(bookDesc.length !==0){
            let data = {
                "bookDesc": bookDesc,
            }
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Value of an Existing Document Field has been updated");
                setAuthor("");
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })
        }
        // Update Book Thumbnail
        if(thumbnailInputRef !== null){
            const bookThumbnailRef = ref(storage,`books/${bookThumbnail.name + v4()}`);
            uploadBytes(bookThumbnailRef,bookThumbnail)
            .then((snapshot)=>{
                const uploadTask = uploadBytesResumable(bookThumbnailRef, bookThumbnail);
                uploadTask.on("state_changed",
              (snapshot) => {
              },
              (error) => {
                alert(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //  setUrl(downloadURL)
                    let data = {
                        "bookThumbnail": downloadURL,
                    }
                    updateDoc(docRef, data)
                    .then(docRef => {
                        console.log("Value of an Existing Document Field has been updated");
                        thumbnailInputRef.current.value = null;
                        setLoading(false)
                    })
                    .catch(error => {
                        console.log(error);
                    })
                     
                });
              }
            );
            })
            
        }
         // Update Book File
         if(fileInputRef !== null){
            const fileRef = ref(storage,`books/${file.name + v4()}`);
            uploadBytes(fileRef,file)
            .then((snapshot)=>{
                const uploadTask = uploadBytesResumable(fileRef, file);
                uploadTask.on("state_changed",
              (snapshot) => {
              },
              (error) => {
                alert(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    //  setUrl(downloadURL)
                    let data = {
                        "url": downloadURL,
                    }
                    updateDoc(docRef, data)
                    .then(docRef => {
                        console.log("Value of an Existing Document Field has been updated");
                        fileInputRef.current.value = null;
                        alert("File Updated")
                        setLoading(false)
                    })
                    .catch(error => {
                        console.log(error);
                    })
                     
                });
              }
            );
            })
            
        }
       
    }
    // Retreive Book Data
    useEffect( () => {
        let id = params.id;
         const docRef = doc(db,'books',id)
        const unsubscribe =  onSnapshot(docRef,(doc)=>{
            let data = doc.data();
            if(data){
                setBook(doc.data());
                setNoData(0)
            }
            else{
                setNoData(1)
            }
            })
            
        
        return () => unsubscribe();
    },[])
   
  return (
    <div className=' min-h-screen flex'>
    <div className="left flex-[1_1_0%]">
    <Sidebar />
    </div>
    <div className="right flex-[3_3_0%]">
    <div className="content">
      <MainNav />
      <div className="courses mx-6">
     {noData === 1 ? 
     <>
     <h1 className='text-3xl py-4 text-primaryColor text-center font-bold'>No Record Found With The Provided ID</h1>
     </>
     :
     <>
     <div className="courseHeader flex justify-center flex-col my-6">
      
      <h1 className='text-3xl py-2 text-primaryColor text-center font-bold tracking-widest'>{book.bookName}</h1>
      <h1 className='text-lg py-2 text-primaryColor text-center font-bold '>Update Book Info</h1>

      {/* Update Course Form */}
      <div className="mt-2 max-[600px] max-auto text-center sm:text-left">
                                        <div className="items-center gap-2 mt-3 w">
                                            <form>
                                            <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Prev Book name: <small className="font-bold">{book.bookName}</small> </label>
                                          <input type="text" placeholder='Updated Book name' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={bookName} onChange={e => setBookName(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Author: <small className="font-bold">{book.author}</small> </label>
                                          <input type="text" placeholder='Updated Author' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={author} onChange={e => setAuthor(e.target.value)} />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label className='text-primaryColor py-2'>Prev Thumbnail: 
                                          <img src={book.bookThumbnail} alt="" className="h-24 w-24" />
                                          </label>
                                          <input type="file" placeholder='Updated Vimeo URL' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' ref={thumbnailInputRef} onChange={e => setBookThumbnail(e.target.files[0])}  />
                                      </div>
                                      <div className="form-group flex flex-col py-2">
                                          <label className='text-primaryColor mt-2 mb-4'>Prev File:  
                                          <a href={book.url} target="_blank" rel="noopener noreferrer" className='text-black bg-secondaryColor py-2 w-max my-2 p-2 rounded-md'><i className="fa fa-eye"></i> Download File</a>
                                          </label>
                                          <input type="file" placeholder='Updated File URL' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' ref={fileInputRef} onChange={e => setFile(e.target.files[0])}  />
                                      </div>
                                      <div className="form-group flex flex-col">
                                          <label  className='text-primaryColor py-2'>Prev Description: <small className="font-bold">{book.bookDesc}</small> </label>
                                          <textarea  cols="10" rows="3" placeholder='Updated Course Description' className='py-2  focus:outline-0 rounded-xl border-primaryColor/25 px-4 border-2' value={bookDesc} onChange={e => setBookDesc(e.target.value)}></textarea>
                                      </div>
                                         
                                            {
                                              loading ? 
                                              <>
                                              <div className="w-full flex justify-center items-center">
                                            <ReactLoading type="cylon" color="#007474" height={'10%'} width={'10%'} />
                                            </div>
                                              </>
                                              :
                                              <>
                                              <button
                                                className="w-full mt-2 p-2.5 flex-1  text-white bg-primaryColor rounded-md outline-none"
                                                onClick={updateBook}
                                            >
                                                Update Book
                                            </button>
                                              </>
                                            }
                                            </form>
                                           
                                        </div>
                                    </div>
      </div>
     </>
     }


 
     
      </div>
    </div>
    </div>
    
    </div>
  )
}

export default UpdateBook